import { getENV } from "./getEnv";

/**
 * Returns CKEditor license key, based on current env
 * @returns license key
 */
export function getCKEditorKey(): string {
  const env = getENV();
  return env && env === "PRODUCTION"
    ? "UGZrWUVGamt3VFlPaGVMSmlSbng0bWRxb1R4dWpEa2sxbE42dlMyeUxDb3lqWk01cnBjOVVqYz0tTWpBeU5EQTFNak09"
    : "SnV1cEtyUjUxMVMvTGFRcHgya3lhNGlkNEVkb2JxbEcrRUlzZ1I0WE1SMjlkYStqMlU5N05xaz0tTWpBeU5EQTFNak09";
}
