import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  Alert,
  AlertTitle,
  ModalProps,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import {
  useRef,
  useState,
  useCallback,
  FunctionComponent,
  useMemo,
  useEffect,
} from "react";
import { Color } from "../colors";
import { stylesheet } from "../stylesheet";
import { UploadSection } from "./upload-section/UploadSection";
import {
  DefaultApiAttachmentsCreateRequest,
  DefaultApiAttachmentsUpdateRequest,
  AttachmentLinkEnum,
  DocumentResponse,
} from "@akitabox/api-client";
import { useApiMutation } from "../hooks/useApiMutation";
import { api } from "../api";
import { FileListSection } from "./file-list-section/FileListSection";
import { fireAndforget } from "../../utils/fireAndForget";
import { capitalizeText } from "../../utils/capitalizeText";
import { useGlobalSnackbar } from "../consecutive-snackbar/GlobalSnackbar";
import { FilterKind } from "./file-list-section/FilterBar";
import { AttachmentDialogUpload } from "./AttachmentDialogService";
import { constants } from "../../utils/constants";
import { makeUseServiceCall } from "../hooks/useServiceCall";

export type BulkActionData =
  | {
      action: "attach";
      fileName: string;
      data: DefaultApiAttachmentsCreateRequest[];
    }
  | {
      action: "detach";
      fileName: string;
      data: DefaultApiAttachmentsUpdateRequest[];
    }
  | {
      action: "clear";
    };
export type SelectedBulkFiles = Record<DocumentResponse["_id"], BulkActionData>;
export type SelectedFiles = Record<DocumentResponse["_id"], DocumentResponse>;

export interface AttachmentDialogProps extends DialogProps {
  organization: string;
  building?: string;
  entityType: AttachmentLinkEnum;
  entityIds?: string[];
  skipCreatingAttachments: boolean;
  imagesOnly?: boolean;
  isReportBuilder?: boolean;
  onClose?: (createdDocuments: AttachmentDialogUpload[]) => void;
  canUploadMultipleFiles?: boolean;
}

export const AttachmentDialog: FunctionComponent<AttachmentDialogProps> = ({
  organization,
  building,
  onClose,
  entityType,
  entityIds,
  skipCreatingAttachments,
  imagesOnly = false,
  isReportBuilder = false,
  canUploadMultipleFiles = false,
  ...rest
}) => {
  const { simple } = useGlobalSnackbar();

  /**
   * Ref array of docs' ids that were successfully processed/attached.
   * Used within the retry action in order to filter out documents that already have been processed.
   * This way we make sure to pass only the failed/non-processed documents when users retries.
   */
  const processedDocs = useRef<DocumentResponse["_id"][]>([]);

  /**
   * Utility function that returns an object with
   * all functions needed to handle mutations
   * on the array of processed documents.
   */
  const processedDocsFns = useCallback(
    () => ({
      /** Clears out the array of processed docs */
      clearProcessedList: () => {
        if (processedDocs.current.length === 0) return;
        processedDocs.current = [];
      },
      /** Adds new document to the array of processed docs */
      markAsProcessed: (documentId: DocumentResponse["_id"]) =>
        processedDocs.current.push(documentId),
      /** Checks if the given document has already been processed */
      isProcessed: (documentId: DocumentResponse["_id"]) =>
        processedDocs.current.includes(documentId),
    }),
    []
  );

  /**
   * API call for uploading files to create abx documents
   */
  const { trigger: triggerDocumentUpload, isMutating: isUploadingDocuments } =
    useApiMutation(api.documents.upload);

  /**
   * API call for creating attachments
   */
  const { trigger: triggerAttachmentCreate, isMutating: isSavingAttachments } =
    useApiMutation(api.attachments.create);
  /**
   * API call for updating attachments
   */
  const {
    trigger: triggerAttachmentUpdate,
    isMutating: isUpdatingAttachments,
  } = useApiMutation(api.attachments.update);

  /**
   * Currently selected building. Used when no building is passed in
   * to allow selection from within the dialog. Note that this means
   * changes to the `building` prop will not be respected. If we need
   * to for whatever reason in the future, we'll need to do some minor work
   * here.
   */
  const [selectedBuilding, setSelectedBuilding] = useState(building);
  useEffect(() => {
    setSelectedBuilding(building);
  }, [building]);
  /**
   * API call for fetching buildings
   */
  const { data: buildings } = makeUseServiceCall(
    api.buildings.getByOrganization,
    !building
  )({
    organizationId: organization,
    sort: "name,asc",
  });

  /**
   * Array of documents that have been created and the files that were uploaded
   * to create them.
   */
  const [uploadedFiles, setUploadedFiles] = useState<AttachmentDialogUpload[]>(
    []
  );
  /**
   * Array of files that have been selected but have not finished uploading.
   */
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);
  /**
   * Map of documents that have been selected from the documents list.
   */
  const [selectedFiles, setSelectedFiles] = useState<SelectedFiles>({});
  /**
   * Map of documents that have been bulk selected from the documents list.
   */
  const [selectedBulkFiles, setSelectedBulkFiles] = useState<SelectedBulkFiles>(
    {}
  );
  /**
   * Array of files' names that errored during attachments' creation
   */
  const [erroredAttachments, setErroredAttachments] = useState<string[]>([]);

  /**
   * Controls for the dialog's top progress bar. Each should be
   * in [0, 100). `buffer` is used to indicate the progress of
   * requests that are pending a response.
   *
   * @example
   * setProgressBar({ value: 50, buffer: 75 })
   * // would show something like
   * // ======---...
   */
  const [progressBar, setProgressBar] = useState({
    value: 0,
    buffer: 0,
  });

  /**
   * Event handler for the removal of an uploaded document pending
   * attachment.
   * @param document The document to "forget"
   */
  const handleDocumentRemove = (document: DocumentResponse) => {
    setUploadedFiles((uploads) => [
      ...uploads.filter(({ document: doc }) => doc._id !== document._id),
    ]);
  };

  /**
   * Event handler for files being submitted for uploading as abx documents.
   * @param file The file to upload
   * @returns A promise that resolves when
   */
  const handleFileUpload = async (file: File) => {
    if (!selectedBuilding) {
      throw new Error("A building must be selected to upload files");
    }
    // mark the file as pending upload
    setPendingFiles((files) => [...files, file]);
    try {
      const response = await triggerDocumentUpload({
        args: {
          building: selectedBuilding,
          file,
          attachment: isReportBuilder ? false : true,
        },
      });
      // track the newly created document
      setUploadedFiles((uploads) => [
        ...uploads,
        { document: response.data[0], file },
      ]);
    } catch (err) {
      const error =
        err instanceof Error ? err : new Error("Unknown network error");

      // display an error toast
      simple(error.message, { severity: "error" });
    } finally {
      // ensure we always clear the pending file
      setPendingFiles((files) => files.filter((f) => f !== file));
    }
  };

  /**
   * Event handler for closing the dialog.
   */
  const handleClose = useCallback<Exclude<ModalProps["onClose"], undefined>>(
    (_event, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      // using event do handle close with selected images
      if (_event) {
        // doing TS magic to inject type
        const event = _event as { action: string };
        if (event && event.action === "close") {
          onClose?.([]);
          return;
        }
      }
      // merge uploaded files with selected files
      const createdDocuments = [...uploadedFiles];
      for (const document of Object.values(selectedFiles)) {
        createdDocuments.push({ document });
      }
      onClose?.([...createdDocuments]);
    },
    [onClose, uploadedFiles, selectedFiles]
  );

  /**
   * Event handler for finalizing changes in the dialog. Creates all
   * attachments queued up in the dialog, and closes.
   */
  const handleSave = useCallback(() => {
    if (skipCreatingAttachments) {
      handleClose({}, "escapeKeyDown");
      return;
    }
    if (!selectedBuilding) {
      throw new Error("Select a building to attach images");
    }
    fireAndforget(async () => {
      const numAttachmentsToProcess =
        Object.keys(selectedFiles).length +
        uploadedFiles.length +
        Object.keys(selectedBulkFiles).length;

      // percentage of progress completed with every document attached
      const progressTickSize = 100 / numAttachmentsToProcess;
      setProgressBar({
        buffer: 0,
        value: 0,
      });

      // setting errored attachments to empty so it doesn't show when retrying
      setErroredAttachments([]);

      // grabing processed documents' list utility functions
      const { isProcessed, markAsProcessed, clearProcessedList } =
        processedDocsFns();

      /**
       * Array of work to do to create attachments. Includes progress
       * bar manipulation, and error handling. None of these functions
       * will reject.
       */
      const attachFns = [
        ...uploadedFiles.map((file) => file.document),
        ...Object.values(selectedFiles),
      ]
        // removes already processed documents
        .filter((document) => !isProcessed(document._id))
        // maps over pending/errored documents
        .map((document) => async () => {
          setProgressBar((pb) => ({
            ...pb,
            buffer: pb.buffer + progressTickSize,
          }));

          const buildingToUse = building || selectedBuilding;
          if (buildingToUse) {
            try {
              await Promise.all(
                !entityIds
                  ? []
                  : entityIds.map((entity_id) =>
                      triggerAttachmentCreate({
                        args: {
                          building: buildingToUse,
                          attachment: {
                            document: document._id,
                            links: [
                              {
                                entity_id,
                                entity_type: entityType,
                              },
                            ],
                          },
                        },
                      })
                    )
              );

              // save successfully attached document reference
              markAsProcessed(document._id);
            } catch (_err: any) {
              throw new Error(`${document.name}${document.extension || ""}`);
            } finally {
              setProgressBar((pb) => ({
                ...pb,
                value: pb.value + progressTickSize,
              }));
            }
          }
        });

      const erroredDocs: string[] = [];
      for (const attachDocument of attachFns) {
        // PERF: We could do several parallel calls here to cut down on time to
        // attach a large number of documents.
        try {
          await attachDocument();
        } catch (err: any) {
          // casting as Error here cause we know for sure it's gonna be
          erroredDocs.push((err as Error).message);
        }
      }

      /**
       * Array of work to bulk attach/detach file attachments.
       * Includes progress bar manipulation, and error handling.
       */
      const bulkFns = [...Object.entries(selectedBulkFiles)]
        // removes already processed documents
        .filter(([docId]) => !isProcessed(docId))
        // maps over pending/errored documents
        .map(([docId, bulkActionData]) => async () => {
          setProgressBar((pb) => ({
            ...pb,
            buffer: pb.buffer + progressTickSize,
          }));

          try {
            if (bulkActionData.action === "attach") {
              await Promise.all(
                bulkActionData.data.map((args) =>
                  triggerAttachmentCreate({ args })
                )
              );
            } else if (bulkActionData.action === "detach") {
              await Promise.all(
                bulkActionData.data.map((args) =>
                  triggerAttachmentUpdate({ args })
                )
              );
            }

            // save successfully bulk document reference
            markAsProcessed(docId);
          } catch (error) {
            throw new Error(
              bulkActionData.action !== "clear"
                ? bulkActionData.fileName
                : docId
            );
          }
        });

      for (const bulkDocuments of bulkFns) {
        // PERF: We could do several parallel calls here to cut down on time to
        // attach a large number of documents.
        try {
          await bulkDocuments();
        } catch (err: any) {
          // casting as Error here cause we know for sure it's gonna be
          erroredDocs.push((err as Error).message);
        }
      }

      if (erroredDocs.length > 0) {
        setErroredAttachments(erroredDocs);
      } else {
        const modelKey = getModelKey(entityType);
        const { SINGULAR, PLURAL } = constants.MODELS[modelKey];
        simple(
          `Successfully processed ${numAttachmentsToProcess} file${
            numAttachmentsToProcess > 0 ? "s" : ""
          } for ${entityIds ? entityIds.length : ""} ${
            entityIds && entityIds.length > 1 ? PLURAL : SINGULAR
          }`
        );

        // clear out processed docs on success
        clearProcessedList();
        // close the dialog
        handleClose({}, "escapeKeyDown");
      }
    });
  }, [
    skipCreatingAttachments,
    uploadedFiles,
    selectedFiles,
    building,
    triggerAttachmentCreate,
    entityIds,
    entityType,
    handleClose,
    simple,
    processedDocsFns,
    selectedBulkFiles,
    triggerAttachmentUpdate,
    selectedBuilding,
  ]);

  const handleFileSelect = useCallback((file: DocumentResponse) => {
    setSelectedFiles((selectedFiles) => {
      if (selectedFiles[file._id]) {
        const { [file._id]: _, ...rest } = selectedFiles;
        return rest;
      }
      return {
        ...selectedFiles,
        [file._id]: file,
      };
    });
  }, []);

  const handleBulkFileSelect = useCallback(
    (fileId: DocumentResponse["_id"], payload: BulkActionData) => {
      setSelectedBulkFiles((selectedBulkFiles) => {
        if (payload.action === "clear") {
          const { [fileId]: _, ...rest } = selectedBulkFiles;
          return rest;
        }
        return {
          ...selectedBulkFiles,
          [fileId]: payload,
        };
      });
    },
    []
  );

  const handleResetSelections = useCallback(() => {
    setSelectedFiles({});
    setSelectedBulkFiles({});
  }, []);

  const createdDocuments = useMemo(
    () => uploadedFiles.map((file) => file.document),
    [uploadedFiles]
  );

  const { toBeBulkAttached, toBeBulkDetached } = useMemo(() => {
    if (Object.keys(selectedBulkFiles).length === 0) {
      return {
        toBeBulkAttached: 0,
        toBeBulkDetached: 0,
      };
    }

    return {
      toBeBulkAttached: Object.values(selectedBulkFiles).filter(
        ({ action }) => action === "attach"
      ).length,
      toBeBulkDetached: Object.values(selectedBulkFiles).filter(
        ({ action }) => action === "detach"
      ).length,
    };
  }, [selectedBulkFiles]);

  const totalFilesToBeAttached = useMemo(() => {
    return (
      Object.keys(selectedFiles).length +
      uploadedFiles.length +
      toBeBulkAttached
    );
  }, [selectedFiles, uploadedFiles, toBeBulkAttached]);

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        data-testid={AttachmentDialogTestids.Dialog}
        {...rest}
        onClose={handleClose}
      >
        <DialogTitle component={Box} css={ss.dialogTitle}>
          <Typography variant="h6" color="inherit">
            Attach Photos & Files
            {entityIds &&
              entityIds.length > 1 &&
              ` to ${entityIds.length} ${capitalizeText(
                constants.MODELS[getModelKey(entityType)].PLURAL
              )}`}
          </Typography>
          <IconButton
            data-testid={AttachmentDialogTestids.CloseButton}
            color="inherit"
            onClick={() => handleClose({ action: "close" }, "escapeKeyDown")}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* Progress bar shown during save. Hides itself at 0 progress automatically */}
        <LinearProgress
          data-testid={AttachmentDialogTestids.ProgressBar}
          variant="buffer"
          value={progressBar.value}
          valueBuffer={progressBar.buffer}
          css={
            progressBar.value === 0 && progressBar.buffer === 0
              ? ss.progressBarHidden
              : undefined
          }
        />
        {
          /* Error alert with message and retry button */
          erroredAttachments.length > 0 && (
            <Alert
              severity="error"
              data-testid={AttachmentDialogTestids.ErrorAlert}
              action={
                <Button
                  onClick={handleSave}
                  data-testid={AttachmentDialogTestids.RetryButton}
                >
                  RETRY
                </Button>
              }
            >
              <AlertTitle>
                {erroredAttachments.length} out of{" "}
                {Object.keys(selectedFiles).length +
                  toBeBulkAttached +
                  toBeBulkDetached +
                  uploadedFiles.length}{" "}
                file(s) failed to process.
              </AlertTitle>
              These are the ones with errors: {erroredAttachments.join(", ")}
            </Alert>
          )
        }
        <DialogContent>
          <Stack>
            {!building && (
              <FormControl fullWidth>
                <InputLabel>Building</InputLabel>
                <Select
                  label="Building"
                  onChange={(e) =>
                    setSelectedBuilding(e.target.value as string)
                  }
                  value={selectedBuilding ?? 1}
                  size="medium"
                  variant="outlined"
                >
                  {buildings?.data.map((building) => (
                    <MenuItem value={building._id} key={building._id}>
                      {building.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {(building || selectedBuilding) && (
              <>
                <Typography css={ss.headerText} variant="body2" align="center">
                  Upload and/or select photos & files to attach
                </Typography>

                <UploadSection
                  css={ss.uploadSection}
                  onDocumentRemove={handleDocumentRemove}
                  onFileUpload={handleFileUpload}
                  documents={createdDocuments}
                  isLoading={isUploadingDocuments}
                  pendingFiles={pendingFiles}
                  isReportBuilder={isReportBuilder}
                  canUploadMultipleFiles={canUploadMultipleFiles}
                />
                <FileListSection
                  entityIds={entityIds}
                  entityType={entityType}
                  building={
                    selectedBuilding
                      ? selectedBuilding
                      : building
                      ? building
                      : ""
                  }
                  selectedFiles={selectedFiles}
                  onFileSelection={handleFileSelect}
                  selectedBulkFiles={selectedBulkFiles}
                  onResetSelections={handleResetSelections}
                  onBulkFileSelection={handleBulkFileSelect}
                  imagesOnly={imagesOnly}
                />
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box css={{ width: "100%" }}>
            <Stack spacing={1}>
              <Typography variant="body2" fontStyle="italic">
                {totalFilesToBeAttached > 0
                  ? `${totalFilesToBeAttached} photo(s)/file(s) selected to be attached`
                  : ""}
                {totalFilesToBeAttached > 0 && toBeBulkDetached > 0
                  ? " | "
                  : ""}
                {toBeBulkDetached > 0
                  ? `${toBeBulkDetached} photo(s)/file(s) selected to be detached`
                  : ""}
              </Typography>
            </Stack>
          </Box>
          <Button
            data-testid={AttachmentDialogTestids.CancelButton}
            onClick={() => handleClose({ action: "close" }, "escapeKeyDown")}
            css={{ fontWeight: "bold" }}
          >
            CANCEL
          </Button>
          <LoadingButton
            loading={isSavingAttachments || isUpdatingAttachments}
            data-testid={AttachmentDialogTestids.SaveButton}
            variant="contained"
            color="primary"
            disabled={
              !(function isEnabled() {
                const hasPendingUploads = pendingFiles.length > 0;
                const hasSavingErrored = erroredAttachments.length > 0;
                const hasUploadedDocuments = uploadedFiles.length > 0;
                const hasSelectedDocuments =
                  Object.keys(selectedFiles).length > 0;
                const hasBulkSelectedDocuments =
                  toBeBulkAttached > 0 || toBeBulkDetached > 0;
                if (
                  !selectedBuilding ||
                  hasPendingUploads ||
                  hasSavingErrored ||
                  isUploadingDocuments ||
                  isSavingAttachments ||
                  isUpdatingAttachments
                ) {
                  return false;
                }
                return (
                  hasUploadedDocuments ||
                  hasSelectedDocuments ||
                  hasBulkSelectedDocuments
                );
              })()
            }
            onClick={handleSave}
            disableElevation
          >
            SAVE
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const AttachmentDialogTestids = {
  /** Test id for the root of the dialog */
  Dialog: "attachmentDialog",
  /** Top-level dialog progress bar */
  ProgressBar: "attachmentDialog.ProgressBar",
  /** Test id for the SAVE button */
  SaveButton: "attachmentDialog.saveButton",
  /** Test id for the CANCEL button */
  CancelButton: "attachmentDialog.cancelButton",
  /** Test id for the x button in the dialog title */
  CloseButton: "attachmentDialog.closeButton",
  /**
   * Test id for the upload button button. Element will contain
   * the actual file input.
   */
  UploadButton: "attachmentDialog.uploadButton",
  /**
   * Test id for the row containing a file.
   * @example
   * `attachmentDialog.uploadListItem.sample.pdf`
   */
  FileListItem: (fileName: string) =>
    `attachmentDialog.uploadListItem.${fileName}`,
  /** Test id for the empty list item in file list section */
  FileListEmpty: "fileListEmpty",
  /** Test id for the toast error component in file list section */
  FileListError: "fileListError",
  /** Test id for the loading indicator in file list section */
  LoadingIndicator: "loadingIndicator",
  /** Test id for the file name text in file list section */
  AttachmentFileName: "attachmentFileName",
  /** Test id for the file size text in file list section */
  AttachmentFileSize: "attachmentFileSize",
  /** Test id for the file thumbnail placeholder in file list section */
  AttachmentPlaceholder: "attachmentPlaceholder",
  /**
   * Test id for rows in the document list
   * @example
   * `attachmentDialog.documentList.row.sample.pdf`
   */
  DocumentRow: (fileName: string) =>
    `attachmentDialog.documentList.row.${fileName}`,
  /**
   * Test id for checkboxes in the document list
   */
  DocumentCheckbox: "attachmentDialog.documentList.row.checkbox",
  /**
   * Test id for indeterminate checkboxes in the document list
   */
  SomeDocumentCheckbox: "attachmentDialog.documentList.row.some.checkbox",
  /**
   * Test id for bulk checkboxes in the document list
   */
  EveryDocumentCheckbox: "attachmentDialog.documentList.row.every.checkbox",
  /**
   * Test id for the document thumbnail in each row of the document
   * list
   */
  DocumentThumbnail: `attachmentDialog.documentList.row.thumbnail`,
  /** Test id for filter bar's Filter button */
  FilterButton: "attachmentDialog.filterButton",
  /** Test id for filter bar's filter type dropdown */
  FilterSelect: "attachmentDialog.filterSelect",
  /** Test id for filter bar's filter value input */
  FilterInput: "attachmentDialog.filterInput",
  /** Test id for the Retry button */
  RetryButton: "attachmentDialog.retryButton",
  /** Test id for the saving error alert */
  ErrorAlert: "attachmentDialog.errorAlert",
  /**
   * Test id for filter bar chips
   * @example
   * `attachmentDialog.filterChip.name`
   */
  FilterChip: (chipName: string | FilterKind) =>
    `attachmentDialog.filterChip.${chipName}`,
  FilterClearAllButton: `attachmentDialog.filterClearAllbutton`,
};

const ss = stylesheet({
  dialogTitle: {
    backgroundColor: Color.MidnightExpress,
    color: Color.White,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: (theme) => ({
    marginTop: theme.spacing(2),
  }),
  uploadSection: (theme) => ({
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
  progressBarHidden: {
    visibility: "hidden",
  },
});

/**
 * Takes in the `entity` type and returns its corresponding key from the `MODELS` constant object.
 *
 * @param {AttachmentLinkEnum} entity - The entity parameter is of type AttachmentLinkEnum, which is an
 * enum that represents the different types of entities that can be linked to an attachment
 * @returns The key of a model in the `constants.MODELS` object
 */
function getModelKey(
  entity: AttachmentLinkEnum
): keyof typeof constants.MODELS {
  switch (entity) {
    case "assembly":
      return "ASSEMBLY";
    case "asset":
      return "ASSET";
    case "checklist":
      return "CHECKLIST";
    case "condition":
      return "CONDITION";
    case "condition_assessment":
      return "CONDITION_ASSESSMENT";
    case "job":
      return "JOB";
    case "level":
      return "FLOOR";
    case "markup":
      return "MARKUP";
    case "request":
      return "SERVICE_REQUEST";
    case "room":
      return "ROOM";
    case "step":
      return "STEP";
    case "task":
      return "WORK_ORDER";
    case "report":
      return "REPORT";
    default:
      throw new Error(`Invalid "${entity}" entity type`);
  }
}
