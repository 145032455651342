import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FunctionComponent, useState } from "react";
import { Color } from "../colors";
import {
  DefaultApiReportTemplatesCreateRequest,
  OrganizationResponse,
  ReportTemplateResponse,
  ReportTemplateResponseContextEnum,
} from "@akitabox/api-client";
import { api } from "../api";
import { useApiMutation } from "../hooks/useApiMutation";

export type CraeteReportTemplateDialog = Omit<DialogProps, "onClose"> & {
  organizationId: OrganizationResponse["_id"];
  onClose?: (reportTemplate?: ReportTemplateResponse) => void;
};

export const CreateReportTemplateDialog: FunctionComponent<
  CraeteReportTemplateDialog
> = ({ organizationId, onClose, ...rest }) => {
  const [name, setName] = useState<string>("");
  const [context, setContext] = useState<
    ReportTemplateResponse["context"] | ""
  >("");
  const onChangeHandler = (
    event: SelectChangeEvent<ReportTemplateResponseContextEnum>
  ) => {
    const value = event.target.value;
    switch (value) {
      case "organization":
        setContext(ReportTemplateResponseContextEnum.Organization);
        break;
      case "building":
        setContext(ReportTemplateResponseContextEnum.Building);
        break;
      default:
        setContext("");
        break;
    }
  };

  const { trigger: createReportTemplate, isMutating: isSavingReportTemplate } =
    useApiMutation(api.reportTemplates.create);

  const onSaveHandler = async () => {
    const args: DefaultApiReportTemplatesCreateRequest = {
      organizationId: organizationId,
      reportTemplate: {
        name,
        context: context as ReportTemplateResponse["context"],
      },
    };
    const newReportTemplate = await createReportTemplate({ args });
    resetInputs();
    if (onClose) onClose(newReportTemplate.data);
  };

  const resetInputs = () => {
    setName("");
    setContext("");
  };

  return (
    <Dialog fullWidth disableEscapeKeyDown {...rest}>
      <DialogTitle
        component={Box}
        css={{
          display: "flex",
          color: Color.White,
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: Color.MidnightExpress,
        }}
      >
        <Typography variant="h6" color="inherit">
          Add Report Template
        </Typography>
        <IconButton
          ariia-label="close"
          color="inherit"
          onClick={() => {
            resetInputs();
            if (onClose) onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            id="report-template-name"
            label="Name"
            variant="standard"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </FormControl>
        <FormControl fullWidth variant="standard">
          <InputLabel id="report-template-context-label">Context</InputLabel>
          <Select
            labelId="report-template-context-label"
            id="report-template-context"
            value={context}
            label="Context"
            onChange={(event) => onChangeHandler(event)}
          >
            <MenuItem value={ReportTemplateResponseContextEnum.Organization}>
              All Buildings
            </MenuItem>
            <MenuItem value={ReportTemplateResponseContextEnum.Building}>
              Single Building
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={isSavingReportTemplate}
          onClick={() => {
            resetInputs();
            if (onClose) onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isSavingReportTemplate || !name || !context}
          onClick={() => onSaveHandler()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
