(function () {
  angular
    .module("akitabox.ui.dialogs.autocomplete")
    .factory("AutocompleteDialog", AutocompleteDialog);

  /* @ngInject */
  function AutocompleteDialog($log, $q, $mdDialog) {
    var modal = {
      preserveScope: true,
      controller: "AutocompleteDialogController",
      controllerAs: "dialog",
      bindToController: true,
      fullscreen: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Get the template for the dialog
     *
     * @param  {String} itemTemplate Suggestion item template html
     *
     * @return {String}              Template html
     */
    function getDialogTemplate(itemTemplate) {
      return (
        '<md-dialog md-theme="default" class="abx-autocomplete-dialog">' +
        '<md-toolbar md-theme="black" class="abx-autocomplete-toolbar elevation-frame-2dp md-hue-1 md-accent">' +
        '<div class="md-toolbar-tools">' +
        '<md-button class="md-icon-button" aria-label="Back" ng-click="dialog.cancel()">' +
        '<md-icon ng-hide="dialog.loading" class="material-icons fg-dark">arrow_back</md-icon>' +
        "<md-progress-circular " +
        'md-theme="default" ' +
        'ng-show="dialog.loading" ' +
        'class="md-accent" ' +
        'md-mode="indeterminate" ' +
        'md-diameter="20"> ' +
        "</md-progress-circular> " +
        "</md-button>" +
        '<input flex type="text" placeholder="Search" ng-model="dialog.searchText" />' +
        "<md-button " +
        'ng-show="dialog.searchText" ' +
        'class="md-icon-button" ' +
        'aria-label="Clear" ' +
        'ng-click="dialog.clear()">' +
        '<md-icon class="material-icons fg-dark">clear</md-icon>' +
        "</md-button>" +
        "</div>" +
        "</md-toolbar>" +
        '<md-content flex layout="column">' +
        itemTemplate +
        '<div flex ng-show="dialog.showNoResults" layout="column" layout-align="center center" layout-fill>' +
        "<span flex></span>" +
        '<div layout="column">' +
        '<md-icon class="material-icons md-48">search</md-icon>' +
        '<span class="fg-dark pad-16 text-center">No results found</span>' +
        "</div>" +
        "<span flex></span>" +
        "</div>" +
        "</md-content>" +
        "</md-dialog>"
      );
    }

    function getItemTemplate(
      itemName,
      itemText,
      itemTemplate,
      groupBy,
      orderBy
    ) {
      var template;
      // Get item template html
      var itemHtml = itemTemplate || '<p ng-bind="' + itemText + '"></p>';

      if (groupBy) {
        var groupRepeat =
          "(key, value) in dialog.items" +
          " | orderBy:" +
          groupBy +
          " | groupBy:" +
          groupBy;
        var nestedRepeat = itemName + " in value";
        if (orderBy) nestedRepeat += " | orderBy:" + orderBy;
        template =
          "" +
          '<section ng-repeat="' +
          groupRepeat +
          '" class="border-dark-bot">' +
          '<md-subheader class="border-dark-bot md-no-sticky">{{key}}</md-subheader>' +
          '<md-list class="no-padding">' +
          buildItemTemplate(nestedRepeat, itemName, itemText, itemHtml) +
          "</md-list>" +
          "</section>";
      } else {
        var itemRepeat = itemName + " in dialog.items";
        if (orderBy) itemRepeat += " | orderBy:" + orderBy;
        template =
          "" +
          '<md-list class="no-padding border-dark-bot">' +
          buildItemTemplate(itemRepeat, itemName, itemText, itemHtml) +
          "</md-list>";
      }

      return template;

      /**
       * Build list item template html
       *
       * @param  {String} ngRepeat    NgRepeat for list items
       * @param  {String} name        List item variable name
       * @param  {String} label       List item aria label
       * @param  {String} template    List item template
       * @param  {Boolean} [itemDisabled]
       *
       * @return {String}             List item html
       */
      function buildItemTemplate(
        ngRepeat,
        name,
        label,
        template,
        itemDisabled
      ) {
        var ngDisabled = itemDisabled
          ? 'ng-disabled="' + itemDisabled + '" '
          : "";
        return (
          '<md-list-item ng-repeat="' +
          ngRepeat +
          '" ' +
          'class="bg-white" ' +
          'ng-click="dialog.selectItem(' +
          name +
          ')" ' +
          ngDisabled +
          'aria-label="{{' +
          label +
          '}}">' +
          template +
          '<md-divider ng-if="!$last"></md-divider>' +
          "</md-list-item>"
        );
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.jobId` is required
     *
     * @param {Object}    options                        Object containing all options
     * @param {Object}    options.locals                 Object containing variables for the dialog
     * @param {Number}    [options.locals.delay]         Amount of time
     * @param {Array}     [options.locals.items]         The items to show
     * @param {Function}  options.locals.itemExpr        Query function
     * @param {String}    options.locals.itemName        Name of model for query
     * @param {Function}  options.locals.itemField       Field to display for each item
     * @param {String}    [options.locals.itemTemplate]  Template for the items in the list
     * @param {Number}    [options.locals.minLength]     Minimum amount of characters needed before query happens
     * @param {Object}    options.scope                  $scope of what ever is opening this dialog
     *
     * @return {Promise}
     */
    function show(options) {
      // required options check
      var rejectMessage = "Something went wrong. Our team is fixing it.";

      if (!options.locals) {
        $log.error("AutocompleteDialog: Invalid locals provided");
        return $q.reject(rejectMessage);
      } else if (!options.locals.itemName) {
        $log.error("AutocompleteDialog: Invalid itemName provided");
        return $q.reject(rejectMessage);
      } else if (!options.locals.itemExpr) {
        $log.error("AutocompleteDialog: Invalid itemExpr provided");
        return $q.reject(rejectMessage);
      } else if (!options.locals.itemField) {
        $log.error("AutocompleteDialog: Invalid itemField provided");
        return $q.reject(rejectMessage);
      } else if (!options.scope) {
        $log.error("AutocompleteDialog: Invalid scope provided");
        return $q.reject(rejectMessage);
      }

      var itemTemplate = getItemTemplate(
        options.locals.itemName,
        options.locals.itemField,
        options.locals.itemTemplate,
        options.locals.groupBy,
        options.locals.orderBy
      );
      var dialogOptions = angular.extend({}, modal);
      dialogOptions.template = getDialogTemplate(itemTemplate);
      angular.extend(dialogOptions, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
