/* eslint-disable no-console */
(function () {
  angular
    .module("akitabox.desktop.asset.list")
    .controller("AssetListController", AssetListController);

  /* @ngInject */
  function AssetListController(
    // Angular
    $scope,
    $timeout,
    $location,
    $q,
    // AkitaBox
    // Constants
    EVENT_ASSET_CREATE,
    // Dialogs
    CreateAssetDialog,
    AssetExportJobDialog,
    // Services
    AssetService,
    OrganizationService,
    PinService,
    PinTypeService,
    ToastService,
    FeatureFlagService,
    FilterBarManager,
    ManagedModelFieldFilter,
    ManagedPinFieldFilter,
    ManagedEnumPinValueFilter,
    ManagedRoomFilter,
    ManagedLevelFilter,
    ManagedPinTypeFilter,
    ManagedFilterHelpers,
    SessionService,
    // Resolve
    building,
    decommissioned
  ) {
    var self = this;

    // Attributes
    self.assets = null;
    self.building = building;
    self.organization = OrganizationService.getCurrent();
    self.pinTypes = [];
    self.classes = { sidenav: "asset-list-sidenav" };
    self.hiddenActions = {
      move: true,
      delete: true,
      duplicate: true,
      overviewLink: true,
      planViewLink: false,
    };

    self.decommissioned = decommissioned;

    self.subtitle = { name: "Assets" };
    self.fabActions = getFabActions();
    self.activeFilters = null;
    self.selectedAsset = null;
    self.isListViewSidebarEnabled =
      FeatureFlagService.isEnabled("listview_sidebar") || false;
    self.showSidebar = SessionService.getAssetListSidebarToggleState() || false;

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // apply the static ?decommissioned=${decommissioned} portion of the query
        query.decommissioned = decommissioned;
        // update the list
        changeFilters(query);
      },
    });
    // From now all filter options will be organized into sections
    // and we only show the filter bar after all request are done
    self.filterBarManager.loading = true;

    // All filter options will be organized into their sections and added together
    const dropdownOptions = {};
    const show_fca = self.organization && self.organization.show_fca;

    var nameConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Name",
      queryField: "name",
    });

    var decommissionedDateConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Decommissioned Date",
        queryField: "decommissioned_date",
        inputType: "date-range",
        modelValueToFilterValue:
          ManagedFilterHelpers.dateModelValueToFilterValue,
        modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
        filterValueToModelValue:
          ManagedFilterHelpers.dateFilterValueToModelValue,
      }
    );

    var roomLevelOptions = {
      getBuildingId: function () {
        return self.building._id;
      },
      getOrganization: OrganizationService.getCurrent,
    };
    var roomConfig = new ManagedRoomFilter(
      self.filterBarManager,
      roomLevelOptions
    );
    var levelConfig = new ManagedLevelFilter(
      self.filterBarManager,
      roomLevelOptions
    );
    ManagedFilterHelpers.associateAssetRoomLevelFilters({
      room: roomConfig,
      level: levelConfig,
    });

    var placedOnFloorConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Placed on Floor Plan",
        queryField: "percentX",
        inputType: "boolean",
        modelValueToFilterValue: function (placedOnFloor) {
          return placedOnFloor ? "$ne,null" : "null";
        },
        modelValueToChipText: function (placedOnFloor) {
          return placedOnFloor ? "Yes" : "No";
        },
        filterValueToModelValue: function (queryParameter) {
          return [queryParameter === "$ne,null"];
        },
      }
    );

    var categoryConfig = new ManagedPinTypeFilter(self.filterBarManager, {
      displayName: "Category",
      placeholder: "Choose a category",
      getBuildingId: function () {
        return building._id;
      },
    });

    const filterDropdownMap = new Map([
      ["string", "(Text)"],
      ["int", "(Whole Number)"],
      ["boolean", "(Yes/No)"],
      ["float", "(Decimal Number)"],
      ["enum", "(Dropdown List)"],
      ["date", "(Date)"],
      ["url", "(URL)"],
      ["paragraph", "(Paragraph)"],
    ]);

    dropdownOptions["Name"] = nameConfig;
    dropdownOptions["Level"] = levelConfig;
    dropdownOptions["Room"] = roomConfig;
    dropdownOptions["Category"] = categoryConfig;

    // only show the decommissioned_date filter if user is on the
    // decommissioned sub-list
    if (decommissioned === "true") {
      dropdownOptions["Decommissioned Date"] = decommissionedDateConfig;
    }

    dropdownOptions["Placed on Floor Plan"] = placedOnFloorConfig;

    if (show_fca) {
      var totalCostConfig = new ManagedModelFieldFilter(self.filterBarManager, {
        displayName: "Total Cost",
        queryField: "costlines.total_cost_with_adjustments",
        inputType: "float-range",
        modelValueToChipText:
          ManagedFilterHelpers.numberRangeModelValueToChipText,
        modelValueToFilterValue:
          ManagedFilterHelpers.numberRangeModelValueToFilterValue,
        filterValueToModelValue:
          ManagedFilterHelpers.numberRangeFilterValueToModelValue,
      });
      dropdownOptions["Total Cost Config"] = totalCostConfig;
    }

    if (FeatureFlagService.isEnabled("root_asset_size")) {
      const sizeConfig = new ManagedModelFieldFilter(self.filterBarManager, {
        displayName: "Size",
        queryField: "size",
        inputType: "float-range",
        modelValueToChipText:
          ManagedFilterHelpers.numberRangeModelValueToChipText,
        modelValueToFilterValue:
          ManagedFilterHelpers.numberRangeModelValueToFilterValue,
        filterValueToModelValue:
          ManagedFilterHelpers.numberRangeFilterValueToModelValue,
      });
      dropdownOptions["Size"] = sizeConfig;

      const sizeUnitConfig = new ManagedModelFieldFilter(
        self.filterBarManager,
        {
          displayName: "Size Unit",
          queryField: "size_unit",
        }
      );
      dropdownOptions["Size Unit"] = sizeUnitConfig;
    }

    if (self.organization.show_installation_date_field) {
      const installationDateConfig = new ManagedModelFieldFilter(
        self.filterBarManager,
        {
          displayName: "Installation Date",
          queryField: "installation_date",
          inputType: "number-range",
          modelValueToChipText:
            ManagedFilterHelpers.numberRangeModelValueToChipText,
          modelValueToFilterValue: (yearRange) => {
            const parts = [];
            if (yearRange && yearRange.start) {
              parts.push("$gte", new Date(yearRange.start, 0, 0).getTime());
            }
            if (yearRange && yearRange.end) {
              parts.push("$lte", new Date(yearRange.end + 1, 0, 0).getTime());
            }
            return parts.join(",");
          },
          filterValueToModelValue: (queryStringValue) => {
            const yearRange = {};
            if (queryStringValue && queryStringValue.length > 0) {
              const parts = queryStringValue.split(",");
              while (parts.length > 0 && parts.length % 2 === 0) {
                const operation = parts.shift();
                const value = parts.shift();
                const date = new Date(Number(value));
                if (operation === "$gte") {
                  yearRange.start = date.getUTCFullYear() + 1;
                } else if (operation === "$lte") {
                  yearRange.end = date.getUTCFullYear();
                }
              }
            }
            return [yearRange];
          },
        }
      );
      self.filterBarManager.addFilterConfiguration(installationDateConfig);
    }

    self.filterInitPromise = _addAllFCAFilterConfigurations(
      dropdownOptions
    ).then(() => {
      return self.filterBarManager.applyQuery($location.search());
    });

    // Functions
    self.addAsset = addAsset;
    self.changeFilters = changeFilters;
    self.fetchAssets = fetchAssets;
    self.fetchAllAssets = fetchAllAssets;
    self.clearSelectedAsset = clearSelectedAsset;
    self.updateSelectedAsset = updateSelectedAsset;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_ASSET_CREATE, onAssetCreate);
    $scope.$on("asset_selected", onAssetSelection);
    $scope.$on("close_sidebar", clearSelectedAsset);
    $scope.$watch("vm.showSidebar", onToggleSidebar);

    // ------------------------
    //   Private Functions
    // ------------------------

    function _addAllFCAFilterConfigurations(dropdownOptions) {
      return PinTypeService.getAll(
        self.building._id,
        { protected_type: "Asset" },
        {}
      ).then((pinTypes) => {
        /**
         * Holds all the counts of each field's ocurence
         */
        const counts = {};

        self.pinTypes = pinTypes;
        // Makes the options received by the function default for all pinTypes
        Object.keys(dropdownOptions).forEach((key) => {
          counts[key] = pinTypes.length;
        });
        //

        for (let pinType of pinTypes) {
          // eg: pinType = { name: "A", fields: [ { _id: "0" name: "A" } ] } , { name: "B", fields: [ { _id: "1" name: "A" } ] }
          for (let field of pinType.fields) {
            if (field.is_hidden) {
              // ignore hidden fields
              continue;
            } else if (
              field.data_type === "document_array" ||
              field.data_type === "tag_filter" ||
              field.data_type === "tree"
            ) {
              // ignore these types of fields
              continue;
            } else if (field.is_level_field || field.is_room_field) {
              // ignore custom room/level fields, they're already on the asset, and filter already created above
              continue;
            }

            let currentDropdownOption = dropdownOptions[field.name];
            let fieldName = field.name;
            // if currentDropdownOption exists by name but the data types are different, set the name key to name + type
            if (
              currentDropdownOption &&
              field.data_type !== currentDropdownOption.type
            ) {
              fieldName = `${field.name} ${filterDropdownMap.get(
                field.data_type
              )}`;
              // try to find the dropdown again with the new name
              currentDropdownOption = dropdownOptions[fieldName];
            }

            if (currentDropdownOption) {
              // This field has already been added to the list of possible dropdowns, we need to
              // just update the count and see if later we can add it in, no need to initalize
              // it below
              // Update the count, since we've already found this exact field on a previous pin type
              if (Object.prototype.hasOwnProperty.call(counts, fieldName)) {
                // this count gets initialized below where the [currentDropdownOption] is injected
                // into the [dropdownOptions] list. We only want to increment it here if it
                // already exists to avoid any weird unwanted behavior
                counts[fieldName]++;
              }
              continue;
            }

            if (field.data_type === "enum") {
              currentDropdownOption = new ManagedEnumPinValueFilter(
                self.filterBarManager,
                {
                  type: field.data_type,
                  getBuildingId: function () {
                    return self.building._id;
                  },
                  displayName: fieldName,
                  pinFieldName: fieldName, // selects only pin fields with name "Type"
                  placeholder: `Choose a ${fieldName}`,
                  //protected_type: "Asset", // restricts the pin type query to just asset pin types
                  pinTypeConfig: categoryConfig,
                  onInputChange: onInputChangeFn,
                  onAfterGetFilterValue: onAfterFilterValue,
                  onAddFilterError: onAddFilterError,
                }
              );

              categoryConfig.addAssociatedConfiguration(currentDropdownOption);
            } else if (field.data_type === "boolean") {
              currentDropdownOption = new ManagedPinFieldFilter(
                self.filterBarManager,
                {
                  type: field.data_type,
                  displayName: fieldName,
                  queryField: field.name,
                  inputType: "boolean",
                  modelValueToFilterValue: function (isYes) {
                    return isYes ? true : false;
                  },
                  modelValueToChipText: function (isYes) {
                    return isYes ? "Yes" : "No";
                  },
                  filterValueToModelValue: function (queryParameter) {
                    return [queryParameter === true];
                  },
                  onInputChange: onInputChangeFn,
                  onAfterGetFilterValue: onAfterFilterValue,
                  onAddFilterError: onAddFilterError,
                }
              );
            } else if (field.data_type === "int") {
              currentDropdownOption = new ManagedPinFieldFilter(
                self.filterBarManager,
                {
                  displayName: fieldName,
                  queryField: field.name,
                  inputType: "number-range",
                  modelValueToChipText:
                    ManagedFilterHelpers.numberRangeModelValueToChipText,
                  onInputChange: onInputChangeFn,
                  onAfterGetFilterValue: onAfterFilterValue,
                  onAddFilterError: onAddFilterError,
                }
              );
            } else if (field.data_type === "float") {
              currentDropdownOption = new ManagedPinFieldFilter(
                self.filterBarManager,
                {
                  displayName: fieldName,
                  queryField: field.name,
                  inputType: "float-range",
                  modelValueToChipText:
                    ManagedFilterHelpers.numberRangeModelValueToChipText,
                  onInputChange: onInputChangeFn,
                  onAfterGetFilterValue: onAfterFilterValue,
                  onAddFilterError: onAddFilterError,
                }
              );
            } else if (field.data_type === "date") {
              currentDropdownOption = new ManagedPinFieldFilter(
                self.filterBarManager,
                {
                  type: field.data_type,
                  displayName: fieldName,
                  queryField: field.name,
                  inputType: "date-range",
                  modelValueToFilterValue:
                    ManagedFilterHelpers.dateModelValueToFilterValue,
                  modelValueToChipText:
                    ManagedFilterHelpers.dateModelValueToChipText,
                  filterValueToModelValue:
                    ManagedFilterHelpers.dateFilterValueToModelValue,
                  onInputChange: onInputChangeFn,
                  onAfterGetFilterValue: onAfterFilterValue,
                  onAddFilterError: onAddFilterError,
                }
              );
            } else {
              currentDropdownOption = new ManagedPinFieldFilter(
                self.filterBarManager,
                {
                  type: field.data_type,
                  displayName: fieldName,
                  queryField: field.name,
                  onInputChange: onInputChangeFn,
                  onAfterGetFilterValue: onAfterFilterValue,
                  onAddFilterError: onAddFilterError,
                }
              );
            }

            currentDropdownOption.type = field.data_type;
            dropdownOptions[fieldName] = currentDropdownOption;
            counts[fieldName] = 1;
          }
        }
        const allDropdownOptionsKeys = Object.keys(dropdownOptions);
        const commonOptions = allDropdownOptionsKeys
          /**
           * This is necessary because we only want fields that are in every
           * pin type of this building. So if the counts matches the length
           * of all pin types, we should add it to the dropdown list of filters
           */
          .filter((key) => counts[key] === pinTypes.length)
          .sort();
        const otherOptions = allDropdownOptionsKeys
          /**
           * This is necessary because we only allow fields are in common with one or more,
           * but not all, Asset Categories in the Building
           */
          .filter((key) => !commonOptions.includes(key))
          .sort();

        const promises = [];
        const addFilterSection = (sectionName, options) => {
          if (!options.length) return;

          const newSection = new ManagedPinFieldFilter(self.filterBarManager, {
            displayName: sectionName,
            queryField: sectionName,
          });
          self.filterBarManager.addFilterConfiguration(newSection);

          for (let key of options) {
            // call .addFiterConfiguration() to each
            promises.push(
              self.filterBarManager
                .addFilterConfiguration(dropdownOptions[key])
                .catch((err) => {
                  if (err.name === "FilterNameAlreadyInUseError") {
                    dropdownOptions[
                      key
                    ].chipName = `${dropdownOptions[key].displayName} (1)`;
                    dropdownOptions[
                      key
                    ].displayName = `${dropdownOptions[key].displayName} (1)`;
                    self.filterBarManager.addFilterConfiguration(
                      dropdownOptions[key]
                    );
                  }
                })
            );
          }
        };

        addFilterSection("Common", commonOptions);
        addFilterSection("Other Fields", otherOptions);
        // Disabled options will only be used to name the sections
        self.filterBarManager.disabledFilterOptions = [
          "Common",
          "Other Fields",
        ];

        return $q.all(promises).then(() => {
          // The first common option will be the default selected filter
          const firstFilterOption = commonOptions[0];
          self.filterBarManager.setSelectedFilter(
            dropdownOptions[firstFilterOption]
          );

          self.filterBarManager.loading = false;
        });
      });
    }

    function onInputChangeFn(event) {
      if (event.newValue) {
        event.newValue.data_type = this.type;
      }
      ManagedPinFieldFilter.prototype.onInputChange.call(this, event);
    }

    async function onAfterFilterValue(filterValue) {
      if (
        filterValue &&
        filterValue.data_type &&
        filterValue.data_type !== this.type
      ) {
        return undefined;
      }

      return filterValue;
    }

    function onAddFilterError(err) {
      if (err.name !== "FilterNameAlreadyInUseError") {
        ToastService.showSimple(
          "Error adding " + this.displayName + " to the list of filters"
        );
      }
    }

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportAssets,
        },
      ];
    }

    function exportAssets() {
      var locals = {
        route: AssetService.buildListRoute(building._id),
        filters: self.activeFilters,
        excelOnly: true,
      };
      AssetExportJobDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    /**
     * Handle asset creation event
     *
     * @param {Event}   $event    Angular event
     * @param {Asset[]} assets    List of new assets
     */
    function onAssetCreate($event, assets) {
      // We can only create one asset at a time
      var asset = assets[0];
      if (asset.building === self.building._id) {
        PinService.includePinType(asset.building, [asset]).then(function (
          results
        ) {
          if (!self.assets) {
            self.assets = [];
          }
          self.assets.unshift(results[0]);
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        });
      }
    }

    function onToggleSidebar() {
      SessionService.setAssetListSidebarToggleState(self.showSidebar);
    }

    function onAssetSelection(_event, asset) {
      const shouldShowSidebar =
        self.isListViewSidebarEnabled && self.showSidebar;
      if (shouldShowSidebar && asset) {
        AssetService.getById(
          asset.building,
          asset._id,
          { include_values: true },
          { includePinType: true, includeFloor: true, includeRoom: true }
        )
          .then((model) => {
            self.selectedAsset = model;
          })
          .catch(ToastService.showError);
      } else {
        self.selectedAsset = null;
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------
    function clearSelectedAsset() {
      self.selectedAsset = null;
    }

    function updateSelectedAsset($event) {
      const { field, pinField, newValue } = $event;
      const { _id, values, building } = self.selectedAsset;
      if (field === "values") {
        const pinValue = values[pinField._id];
        AssetService.updateValueByDataType(
          building,
          pinField.data_type,
          _id,
          pinValue._id,
          newValue,
          { pin: self.selectedAsset }
        ).catch(ToastService.showError);
      } else {
        AssetService.updateById(
          building,
          _id,
          { [field]: newValue },
          {},
          self.selectedAsset
        ).catch(ToastService.showError);
      }
    }

    function addAsset() {
      CreateAssetDialog.show({ locals: { building: building } })
        .then(function (assets) {
          // We know we're only getting one back, so take the first item in the returned array
          var newAsset = assets[0];

          if (angular.isArray(self.assets)) {
            self.assets.unshift(newAsset);
          } else {
            self.assets = [newAsset];
          }
          $timeout(function () {
            $scope.$broadcast("list:updateSelectedIndices", assets.length);
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function fetchAssets(skip, limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(
          self.activeFilters,
          "decommissioned"
        )
      ) {
        $location.search("decommissioned", decommissioned);
      }
      var doFetch = function () {
        var params;
        params = {
          skip: skip,
          limit: limit,
          include_values: true,
          decommissioned: decommissioned,
        };

        var options = {
          includePinType: true,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        const setAssets = function (assets) {
          let asset = updateAssetsProps(assets);
          self.assets = angular.isArray(self.assets)
            ? self.assets.concat(asset)
            : asset;
          return assets.length;
        };

        return AssetService.getByBFFBuilding(
          self.organization._id,
          self.building._id,
          filters,
          options
        ).then(setAssets);
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function updateAssetsProps(assets) {
      return assets.map((asset) => {
        asset.building = asset.building._id;
        if (asset.room) asset.room.value = asset.room._id;
        if (asset.level) asset.level.value = asset.level._id;
        return {
          ...asset,
        };
      });
    }

    function fetchAllAssets(limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(
          self.activeFilters,
          "decommissioned"
        )
      ) {
        $location.search("decommissioned", decommissioned);
      }

      var doFetch = function () {
        var params;
        params = {
          skip: 0,
          limit: limit,
          include_values: true,
          decommissioned: decommissioned,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        const setAssets = function (assets) {
          let asset = updateAssetsProps(assets);
          self.assets = angular.isArray(self.assets)
            ? self.assets.concat(asset)
            : asset;
          return assets.length;
        };

        return AssetService.getByBFFBuilding(
          self.organization._id,
          self.building._id,
          filters
        ).then(setAssets);
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.assets = null;
      $scope.$broadcast("list:refresh");
    }
  }
})();
