import moment from "moment";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { GridActionsCellItem, GridSortModel } from "@mui/x-data-grid-pro";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { Link, Navigate, useRouteLoaderData } from "react-router-dom";
import { useMemo, useState, useCallback, FunctionComponent } from "react";
import {
  UserResponse,
  ReportResponse,
  OrganizationResponse,
} from "@akitabox/api-client";
import {
  Box,
  Stack,
  Button,
  Dialog,
  Tooltip,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { api } from "../../api";
import { Color } from "../../colors";
import { stylesheet } from "../../stylesheet";
import { useApiMutation } from "../../hooks/useApiMutation";
import { makeUseServiceCall } from "../../hooks/useServiceCall";
import { AbxGridColDef, AbxDataGrid } from "../abx-data-grid/AbxDataGrid";
import { useGlobalSnackbar } from "../../consecutive-snackbar/GlobalSnackbar";
import { ConfirmationDialog } from "../../confirmation-dialog/ConfirmationDialog";
import { getHref } from "../../../utils/getHref";
import { getMultiLevelPropertyValue } from "../../../utils/getMultiLevelPropertyValue";

export type ReportBuilderListActionMode =
  | "create"
  | "edit"
  | "delete"
  | "clone";

export type ReportBuilderListRefetchOptions = {
  mode: Exclude<ReportBuilderListActionMode, "delete">;
  report: ReportResponse;
};

type ReportData = {
  id: string;
  name: string;
  organization_id?: string;
  copy_images?: boolean;
};

export const ReportBuilderList: FunctionComponent = () => {
  // dialogs
  const { simple } = useGlobalSnackbar();
  const { organization, user, flags } = useRouteLoaderData("shell") as {
    organization: OrganizationResponse;
    user: UserResponse;
    flags: { [key: string]: boolean };
  };
  const [mode, setMode] = useState<ReportBuilderListActionMode>();
  // create/edit data
  const [reportData, setReportData] = useState<ReportData>({
    id: "",
    name: "",
  });

  // pagination
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  // sorting
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "cre_date", sort: "desc" },
  ]);

  const { report_builder } = user.permission_group;
  const reportBuilderPermissions = useMemo(
    () => ({
      read: report_builder["read"],
      create: report_builder["create"],
      remove: report_builder["remove"],
      update: report_builder["update"],
    }),
    [report_builder]
  );

  const isReportBuilderEnabled = flags["report_builder"];

  const {
    isLoading: isCounting,
    data: reportsCountResponse,
    mutate: refetchReportsCount,
  } = makeUseServiceCall(api.reports.count)({
    organizationId: organization._id,
  });

  const {
    isLoading,
    data: reportsResponse,
    mutate: refetchReports,
  } = makeUseServiceCall(api.reports.getByOrganization)({
    sort: sortModel.map((item) => `${item.field},${item.sort}`).join(","),
    insensitive: true,
    limit: paginationModel.pageSize,
    organizationId: organization._id,
    skip: paginationModel.page * paginationModel.pageSize,
  });

  const { data: organizationsResponse } = makeUseServiceCall(
    api.organizations.get
  )({
    skip: 0,
    limit: 1000,
  });

  const reportsCount = useMemo(() => {
    if (!reportsCountResponse) return 0;
    return reportsCountResponse.data.count;
  }, [reportsCountResponse]);

  const reports = useMemo(() => {
    if (reportsResponse) return reportsResponse.data;
  }, [reportsResponse]);

  const organizations = useMemo(() => {
    if (organizationsResponse)
      return organizationsResponse.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
  }, [organizationsResponse]);

  const columns = useMemo(() => {
    const colsDef: AbxGridColDef<ReportResponse>[] = [
      {
        field: "name",
        type: "string",
        headerName: "Name",
        abxGridColType: "basic",
        renderCell: ({ row: report, value }) => (
          <Link
            css={ss.link}
            to={{ pathname: `/report_builder/${report._id}` }}
          >
            {value}
          </Link>
        ),
      },
      {
        type: "date",
        field: "last_mod_date",
        abxGridColType: "basic",
        headerName: "Last Modified",
        valueFormatter: (value?: string) =>
          value ? moment(value).format("MMM D, YYYY") : "",
      },
      {
        type: "date",
        field: "cre_date",
        abxGridColType: "basic",
        headerName: "Created Date",
        valueFormatter: (value?: string) =>
          value ? moment(value).format("MMM D, YYYY") : "",
      },
    ];

    if (
      reportBuilderPermissions.update ||
      reportBuilderPermissions.remove ||
      reportBuilderPermissions.create
    ) {
      colsDef.push({
        type: "actions",
        field: "actions",
        abxGridColType: "action",
        getActions: ({ row: report }) => {
          const actions: EmotionJSX.Element[] = [];

          if (reportBuilderPermissions.update) {
            actions.push(
              <GridActionsCellItem
                color="info"
                label="Edit"
                size="medium"
                disableRipple
                key={report._id}
                icon={
                  <Tooltip title="Edit">
                    <EditIcon />
                  </Tooltip>
                }
                onClick={() => {
                  // set data on edit
                  setReportData({ id: report._id, name: report.name });
                  setMode("edit");
                }}
              />
            );
          }

          if (reportBuilderPermissions.create) {
            actions.push(
              <GridActionsCellItem
                color="info"
                label="Clone"
                size="medium"
                disableRipple
                key={report._id}
                icon={
                  <Tooltip title="Clone">
                    <FileCopyIcon />
                  </Tooltip>
                }
                onClick={() => {
                  // set data on edit
                  setReportData({
                    id: report._id,
                    name: report.name,
                    organization_id: organization._id,
                  });
                  setMode("clone");
                }}
              />
            );
          }

          if (reportBuilderPermissions.remove) {
            actions.push(
              <GridActionsCellItem
                color="error"
                size="medium"
                label="Delete"
                disableRipple
                key={report.cre_date}
                icon={
                  <Tooltip title="Delete">
                    <DeleteIcon />
                  </Tooltip>
                }
                onClick={() => {
                  // set data on delete
                  setReportData({ id: report._id, name: report.name });
                  setMode("delete");
                }}
              />
            );
          }

          return actions;
        },
      });
    }

    return colsDef;
  }, [reportBuilderPermissions, organization._id]);

  const { trigger: reportCreate, isMutating: isSaving } = useApiMutation(
    api.reports.create
  );

  const { trigger: reportEdit, isMutating: isEditing } = useApiMutation(
    api.reports.update
  );

  const { trigger: reportDelete, isMutating: isDeleting } = useApiMutation(
    api.reports.delete
  );

  const handleClose = useCallback(() => {
    // clear on close
    setMode(undefined);
    setReportData({ id: "", name: "" });
  }, []);

  const getEditDialogTitle = useCallback(() => {
    return `${mode === "create" || mode === "clone" ? "Create new" : "Edit"}${
      mode === "clone" ? " cloned" : ""
    } report`;
  }, [mode]);

  const goToNewReport = useCallback(
    (reportId: string, organizationId: string) => {
      const organizationToGo = organizations?.filter(
        (org) => org._id === organizationId
      );
      if (
        organizationToGo &&
        organizationToGo[0] &&
        organizationToGo[0].subdomain
      ) {
        window.location.assign(
          `${getHref(
            organizationToGo[0].subdomain.key
          )}/report_builder/${reportId}`
        );
      }
    },
    [organizations]
  );

  const optimisticRefetch = useCallback(
    (options: ReportBuilderListRefetchOptions) => {
      switch (options.mode) {
        case "create":
        case "clone":
          if (options.mode === "clone") {
            simple(
              <Stack direction={"row"}>
                <Typography>Successfully cloned report</Typography>
                <Button
                  css={ss.alertViewButton}
                  onClick={() => {
                    goToNewReport(
                      options.report._id,
                      options.report.organization
                    );
                  }}
                >
                  VIEW
                </Button>
              </Stack>
            );
            if (options.report.organization !== organization._id) break;
          }
          // optimistically update table data after create
          // by adding created report to the top of the
          // list and increasing count by 1
          refetchReportsCount(
            (current) => {
              if (!current) return;

              const currentData = { ...current.data };
              const data = { count: currentData.count + 1 };

              return { ...current, data };
            },
            { revalidate: false }
          );
          refetchReports(
            (current) => {
              if (!current) return;

              const currentData = [...current.data];
              if (currentData.length === paginationModel.pageSize) {
                // removes last item if the current list fills the entire page
                currentData.pop();
              }
              const data = [options.report, ...currentData];

              return { ...current, data };
            },
            { revalidate: false }
          );
          break;
        case "edit":
          // optimistically update table data after edit
          // by overriding edited report data on the list
          refetchReports(
            (current) => {
              if (!current) return;

              const currentData = [...current.data];
              const data = currentData.map((currentReport) => {
                if (options.report._id === currentReport._id) {
                  return { ...options.report };
                }
                return currentReport;
              });

              return { ...current, data };
            },
            { revalidate: false }
          );
          break;
        default:
          throw new Error("Invalid refetch option mode");
      }
    },
    [
      refetchReportsCount,
      refetchReports,
      paginationModel,
      organization,
      goToNewReport,
      simple,
    ]
  );

  const showErrorToast = useCallback(
    (err: unknown) => {
      let errorMessage: string;
      if (err instanceof Error) {
        const message = getMultiLevelPropertyValue(
          err,
          "response.data.error.message"
        );
        errorMessage = message ? message : err.message;
      } else {
        errorMessage = "Unknown network error";
      }
      simple(errorMessage, { severity: "error" });
    },
    [simple]
  );

  const onSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (!sortModel.length) {
      setSortModel([{ field: "cre_date", sort: "desc" }]);
      return;
    }
    const sortItem = sortModel[0];
    if (!sortItem) return;
    setSortModel([
      {
        field: sortItem.field,
        sort: sortItem.sort,
      },
    ]);
  }, []);

  if (!isReportBuilderEnabled || !reportBuilderPermissions.read) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Stack
        minHeight="54px"
        alignItems="end"
        padding={(theme) => theme.spacing(1)}
      >
        {reportBuilderPermissions.create && (
          <Button
            css={ss.semiBold}
            variant="contained"
            onClick={() => setMode("create")}
          >
            ADD REPORT
          </Button>
        )}
      </Stack>
      <AbxDataGrid
        forceMinHeight
        columns={columns}
        rowSelection={false}
        rows={reports || []}
        disableColumnPinning
        rowCount={reportsCount}
        loading={isLoading || isCounting}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        pageSizeOptions={[paginationModel.pageSize]}
        onPaginationModelChange={setPaginationModel}
        /** @see https://mui.com/x/react-data-grid/pagination/#cursor-implementation */
      />
      <Dialog
        fullWidth
        onClose={handleClose}
        open={mode === "create" || mode === "edit" || mode === "clone"}
      >
        <DialogTitle component={Box} css={ss.dialogTitle}>
          <Typography variant="h6" color="inherit">
            {getEditDialogTitle()}
          </Typography>
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              required
              autoFocus
              label="Name"
              css={ss.reportName}
              value={reportData.name}
              onChange={({ target }) =>
                setReportData((prev) => ({ ...prev, name: target.value }))
              }
            ></TextField>
          </FormControl>
          {mode === "clone" && (
            <FormControl fullWidth css={ss.organizationField}>
              <InputLabel>Organization</InputLabel>
              <Select
                label="Organization"
                value={reportData.organization_id || organization._id}
                onChange={({ target }) =>
                  setReportData((prev) => ({
                    ...prev,
                    organization_id: target.value,
                  }))
                }
              >
                {organizations?.map((organization) => (
                  <MenuItem key={organization._id} value={organization._id}>
                    {organization.name}
                  </MenuItem>
                ))}
              </Select>
              {reportData.organization_id !== organization._id && (
                <FormControlLabel
                  css={ss.reportActionsCopyImages}
                  control={
                    <Checkbox
                      value={reportData.copy_images}
                      onChange={(event) =>
                        setReportData((prev) => ({
                          ...prev,
                          copy_images: event.target.checked,
                        }))
                      }
                    />
                  }
                  label="Allow copying images from original report to cloned report"
                ></FormControlLabel>
              )}
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button css={ss.semiBold} onClick={handleClose}>
            CANCEL
          </Button>
          <LoadingButton
            color="primary"
            disableElevation
            variant="contained"
            disabled={!reportData.name}
            loading={isSaving || isEditing}
            onClick={async () => {
              let action:
                | ReturnType<typeof reportCreate>
                | ReturnType<typeof reportEdit>;

              switch (mode) {
                case "create":
                  action = reportCreate({
                    args: {
                      organizationId: organization._id,
                      report: { name: reportData.name },
                    },
                  });
                  break;
                case "edit":
                  action = reportEdit({
                    args: {
                      reportId: reportData.id,
                      organizationId: organization._id,
                      report: { name: reportData.name },
                    },
                  });
                  break;
                case "clone":
                  action = reportCreate({
                    args: {
                      organizationId:
                        reportData.organization_id || organization._id,
                      report: {
                        name: reportData.name,
                        organization_id: organization._id,
                        clone_report: reportData.id,
                        copy_images:
                          reportData.organization_id === organization._id
                            ? false
                            : reportData.copy_images,
                      },
                    },
                  });
                  break;
                default:
                  throw new Error(`Invalid action "${mode}"`);
              }

              try {
                const { data: report } = await Promise.resolve(action);
                optimisticRefetch({ mode, report });
                handleClose();
              } catch (error) {
                showErrorToast(error);
              }
            }}
          >
            SAVE
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        fullWidth
        loading={isDeleting}
        title="Delete report"
        onClose={handleClose}
        onCancel={handleClose}
        open={mode === "delete"}
        content={
          <Box paddingTop="20px">
            <Typography variant="body1" component="p">
              Are you sure you want to delete <strong>{reportData.name}</strong>
              ?
            </Typography>
          </Box>
        }
        onConfirm={async () => {
          try {
            await reportDelete({
              args: {
                reportId: reportData.id,
                organizationId: organization._id,
              },
            });
            // full refetch makes new API calls after delete
            refetchReportsCount();
            refetchReports();
            handleClose();
          } catch (error) {
            showErrorToast(error);
          }
        }}
      />
    </>
  );
};

const ss = stylesheet({
  semiBold: { fontWeight: 600 },
  reportName: { marginTop: "24px" },
  reportActionsCopyImages: { paddingTop: "14px", paddingLeft: "4px" },
  link: { cursor: "pointer", textDecoration: "unset" },
  dialogTitle: {
    display: "flex",
    color: Color.White,
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Color.MidnightExpress,
  },
  organizationField: { marginTop: "24px" },
  alertViewButton: { padding: "2px 8px;" },
});
