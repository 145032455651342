(function () {
  angular
    .module("akitabox.desktop.insights", [
      "akitabox.core.services.env",
      "akitabox.core.services.user",
    ])
    .controller("InsightsController", InsightsController);

  /* @ngInject */
  function InsightsController(
    $window,
    $scope,
    OrganizationService,
    EnvService,
    UserService
  ) {
    const sisenseEnvs = new Map([
      ["local", "https://akitabox-dev3.sisense.com"],
      ["temp", "https://akitabox-dev2.sisense.com"],
      ["beta", "https://akitabox-dev1.sisense.com"],
      ["production", "https://akitabox.sisense.com"],
    ]);
    const envName = EnvService.getEnvName();
    const organization = OrganizationService.getCurrent();
    const user = UserService.getCurrent();
    const isLocal = envName === "local";
    // use fields from the dev3 dashboard unless we are in a nonlocal environment
    const filterJaql = !isLocal
      ? {
          table: "dim_buildings",
          dim: `[dim_buildings.organization_name]`,
          datatype: "text",
          filter: {
            multiSelection: true,
            explicit: true,
            members: [organization.name],
          },
          title: "Organization Name",
        }
      : {
          table: "building_replacement_cost",
          dim: `[building_replacement_cost.building_name]`,
          datatype: "text",
          filter: {
            multiSelection: true,
            explicit: true,
            members: ["Test Building"],
          },
          title: "building_name",
        };

    let script = document.createElement("script");
    script.id = "sisense-embed-script";
    script.src = `${sisenseEnvs.get(envName)}/js/frame.js`;
    script.onload = function () {
      const canEdit = user ? user.sisense_role === "designer" : false;
      const { SisenseFrame, enums } = $window["sisense.embed"];
      // embed dev3 test dashboard on local
      const defaultDashboard = !isLocal
        ? "64e4fcde281a2e00324f1526"
        : "636aac20647ae300377dae6b";

      const sisenseFrame = new SisenseFrame({
        url: sisenseEnvs.get(envName),
        dashboard: defaultDashboard,
        settings: {
          showRightPane: true,
          showLeftPane: true,
          showToolbar: true,
        },
        editMode: canEdit,
        element: document.getElementById("sisense-iframe"),
      });

      let orgFilter = JSON.stringify({
        jaql: filterJaql,
      });

      // render sisenseFrame and apply org filter after dashboard is loaded
      sisenseFrame.render().then(() => {
        sisenseFrame.dashboard.on(enums.DashboardEventType.LOADED, function () {
          sisenseFrame.dashboard.applyFilters(orgFilter);
        });
      });
    };

    document.body.appendChild(script);
    $scope.$on("$destroy", function () {
      script.parentNode.removeChild(script);
    });
  }
})();
