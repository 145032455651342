(function () {
  angular.module("akitabox.core.services", [
    "akitabox.core.services.account",
    "akitabox.core.services.assembly",
    "akitabox.core.services.asset",
    "akitabox.core.services.association",
    "akitabox.core.services.associationType",
    "akitabox.core.services.bannerMessage",
    "akitabox.core.services.building",
    "akitabox.core.services.cache",
    "akitabox.core.services.cacheHelpers",
    "akitabox.core.services.cancellable",
    "akitabox.core.services.chart",
    "akitabox.core.services.checklist",
    "akitabox.core.services.checklistTemplate",
    "akitabox.core.services.componentState",
    "akitabox.core.services.condition",
    "akitabox.core.services.costAdjustment",
    "akitabox.core.services.costAdjustmentRule",
    "akitabox.core.services.costData",
    "akitabox.core.services.costLine",
    "akitabox.core.services.costLineTask",
    "akitabox.core.services.document",
    "akitabox.core.services.env",
    "akitabox.core.services.fieldGroup",
    "akitabox.core.services.file",
    "akitabox.core.services.filter",
    "akitabox.core.services.flag",
    "akitabox.core.services.floor",
    "akitabox.core.services.helpers",
    "akitabox.core.services.history",
    "akitabox.core.services.http",
    "akitabox.core.services.inspection",
    "akitabox.core.services.inspectionProgram",
    "akitabox.core.services.job",
    "akitabox.core.services.identity",
    "akitabox.core.services.indexedDB",
    "akitabox.core.services.issueType",
    "akitabox.core.services.maintenanceType",
    "akitabox.core.services.note",
    "akitabox.core.services.organization",
    "akitabox.core.services.pin",
    "akitabox.core.services.pinField",
    "akitabox.core.services.pinType",
    "akitabox.core.services.pinValue",
    "akitabox.core.services.qrcode",
    "akitabox.core.services.recent",
    "akitabox.core.services.redirect",
    "akitabox.core.services.request",
    "akitabox.core.services.recentActivity",
    "akitabox.core.services.room",
    "akitabox.core.services.roundTemplate",
    "akitabox.core.services.schedule",
    "akitabox.core.services.tag",
    "akitabox.core.services.tagCategory",
    "akitabox.core.services.tagCategoryValue",
    "akitabox.core.services.timeZone",
    "akitabox.core.services.timeCode",
    "akitabox.core.services.trade",
    "akitabox.core.services.tree",
    "akitabox.core.services.type",
    "akitabox.core.services.session",
    "akitabox.core.services.shadow",
    "akitabox.core.services.user",
    "akitabox.core.services.userAction",
    "akitabox.core.services.virtualRepeat",
    "akitabox.core.services.workOrder",
    "akitabox.core.services.workOrdersBff",
    "akitabox.core.services.workOrderLog",
    "akitabox.core.services.workOrderRound",
  ]);
})();
