(function () {
  /**
   * @ngdoc controller
   * @name DesktopController
   *
   * @fires asset:create    New asset created
   * @fires floor:create    New floor created
   * @fires request:create  New request(s) created
   * @fires room:create     New room created
   * @fires schedule:create New schedule(s) created
   * @fires task:create     New work order(s) created
   *
   * @description
   * Root controller of the desktop application
   */
  angular
    .module("akitabox.desktop")
    .controller("DesktopController", DesktopController);

  /* @ngInject */
  function DesktopController(
    // Angular
    $scope,
    $window,
    $location,
    $rootScope,
    // Third Party
    $state,
    // AkitaBox
    models,
    // Constants
    EVENT_ASSET_CREATE,
    EVENT_FLOOR_CREATE,
    EVENT_INSPECTION_PROGRAM_CREATE,
    EVENT_QR_CODE_SCANNED,
    EVENT_REQUEST_CREATE,
    EVENT_ROOM_CREATE,
    EVENT_SCHEDULE_CREATE,
    EVENT_WORK_ORDER_CREATE,
    // Libraries
    zendesk,
    // Services
    AppcuesService,
    BuildingService,
    EnvService,
    IdentityService,
    InspectionProgramService,
    OrganizationService,
    RedirectService,
    Router,
    ShadowService,
    ToastService,
    Utils,
    // Dialogs
    AssociateQrCodeDialog,
    CreateAssetDialog,
    CreateFloorDialog,
    CreateInspectionProgramDialog,
    CreateRequestDialog,
    CreateRoomDialog,
    CreateScheduleDialog,
    CreateWorkOrderDialog,
    SelectBuildingForUploadDialog,
    UpdateIdentityNamesDialog
  ) {
    var self = this;

    // Route name regexen
    var rgxSingularRoute =
      /^(app\.building\.(?!reporting)[a-z]*[a-rt-z])(?:(\.[a-z]+)+)?$/i;
    var rgxBuildingDetail = /^app\.building(\.detail)?/i;
    var rgxBuildingInRoute = /^app\.building/i;
    var rgxDashboardPluralOrSingular = /^app\.building\.dashboards?/i;
    var rgxAppOnly = /^app\./i;
    var rgxAdminStatePluralOrSingular = /^app\.building\.admin\.jobs?/i;

    // Constants
    var ALL_BUILDINGS_GROUP = {
      _id: null,
      name: "All Buildings",
      all: true,
    };

    // Attributes
    self.organization = OrganizationService.getCurrent();
    self.building = BuildingService.getCurrent();
    self.group = self.building ? null : ALL_BUILDINGS_GROUP;

    // Functions
    self.onGroupSelect = onGroupSelect;
    self.onBuildingSelect = onBuildingSelect;
    self.getPrimaryHeaderText = getPrimaryHeaderText;
    self.onAdd = onAdd;

    // ------------------------
    //   Life cycle
    // ------------------------

    self.$onInit = function () {
      AppcuesService.init();
      IdentityService.getCurrent().then(function (identity) {
        var prefillData = {
          email: {
            value: identity.email,
            readOnly: true,
          },
        };
        var firstName = identity.first_name;
        var lastName = identity.last_name;
        if (firstName && firstName.length) {
          prefillData.name = {
            value: firstName,
          };
          if (lastName && lastName.length) {
            prefillData.name.value += " " + lastName;
            prefillData.name.readOnly = true;
          }
        }
        zendesk("webWidget", "prefill", prefillData);
      });
      // Attempt to show the UpdateIdentityNamesDialog, see method for logic
      UpdateIdentityNamesDialog.show(); // TODO: (BB) update zendesk prefill
    };

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on("building:change", function (event, building) {
      if (building) {
        self.group = null;
      } else {
        self.group = ALL_BUILDINGS_GROUP;
      }
      self.building = building;
    });

    $scope.$on(EVENT_QR_CODE_SCANNED, handleQrCodeScan);

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Handle group selection
     *
     * @param {Object} $event event object
     */
    function onGroupSelect($event) {
      var organization = $event.organization;
      var group = $event.group || $event.model;
      if (!Utils.isSameModel(organization, self.organization)) {
        RedirectService.redirectToOrganization(organization, "/buildings");
        return;
      }

      self.building = null;
      BuildingService.setCurrent(null);

      // TODO: Implement Groups
      self.group = group;
      if (self.group && self.group.all) {
        var newState = $state.current.name;
        if (rgxSingularRoute.test(newState)) {
          newState = newState.replace(rgxSingularRoute, "$1s");
        }
        if (rgxBuildingDetail.test(newState)) {
          newState = newState.replace(rgxBuildingDetail, "app");
        }

        if (newState === "app" || newState === "apps") {
          newState = "app.dashboard";
        }
        var newParams = getStateParams();

        Router.go(newState, newParams);
      }
    }

    /**
     * Handle building selection
     *
     * @param {Object} $event event object
     */
    function onBuildingSelect($event) {
      if ($event.marker) {
        ShadowService.sendEvent("homepage", "map-nav-to-bldg");
      }

      var organization = $event.organization;
      var building = $event.building || $event.model;
      if (!Utils.isSameModel(organization, self.organization)) {
        RedirectService.redirectToOrganization(
          organization,
          "/buildings/" + building._id
        );
        return;
      }

      self.group = null;
      self.building = building;
      if (self.building) {
        var newState = rgxBuildingInRoute.test($state.current.name)
          ? $state.current.name
          : $state.current.name.replace(rgxAppOnly, "app.building.");

        if (rgxAdminStatePluralOrSingular.test(newState)) {
          newState = "app.building.admin.jobs";
        }

        if (
          rgxSingularRoute.test(newState) &&
          !rgxAdminStatePluralOrSingular.test(newState)
        ) {
          newState = newState.replace(rgxSingularRoute, "$1s");
        }

        if (
          newState === "app.building.details" ||
          rgxDashboardPluralOrSingular.test(newState)
        ) {
          newState = "app.building.detail";
        }

        var newParams = getStateParams({ buildingId: self.building._id });

        Router.go(newState, newParams);
      }
    }

    function getPrimaryHeaderText() {
      if (self.organization) {
        if (self.building) {
          return self.building.name;
        } else if (self.group) {
          return self.group.name;
        }
      } else if (!self.organization) {
        return "AkitaBox";
      }

      return null;
    }

    /**
     * @param {Object} include any state variables to include in the new state param object
     */
    function getStateParams(include) {
      // Add any state params to carry over on navigation here
      var carriedParams = ["status"];
      var newState = angular.extend({}, include || {});
      angular.forEach(carriedParams, function (key) {
        if (typeof $state.params[key] !== "undefined") {
          newState[key] = $state.params[key];
        }
      });

      return newState;
    }

    /**
     * Handle the addition of new models
     *
     * @param {Object}  $event                event object
     * @param {String}  $event.model  model that was chosen
     */
    function onAdd($event) {
      var locals = {
        building: self.building,
        organization: self.organization,
        floor: self.floor,
      };

      if ($event && $event.model) {
        switch ($event.model) {
          case models.ASSET.MODEL:
            CreateAssetDialog.show({
              locals: locals,
            }).then(function (assets) {
              if (assets && assets.length) {
                $rootScope.$broadcast(EVENT_ASSET_CREATE, assets);
              }
            });
            break;
          case models.DOCUMENT.MODEL:
            if (self.building) {
              var url =
                EnvService.getCurrentBaseUrl() +
                "/buildings/" +
                self.building._id +
                "/upload";

              if (EnvService.getEnvName() === EnvService.LOCAL.ENV) {
                url = url.replace(":3007", ":3000");
              }

              $window.open(url, "_blank");
            } else {
              SelectBuildingForUploadDialog.show({
                locals: locals,
              });
            }
            break;
          case models.FLOOR.MODEL:
            CreateFloorDialog.show({
              locals: locals,
            }).then(function (floors) {
              if (floors && floors.length) {
                $rootScope.$broadcast(EVENT_FLOOR_CREATE, floors);
              }
            });
            break;
          case models.SERVICE_REQUEST.MODEL:
            CreateRequestDialog.show({
              locals: locals,
            }).then(function (requests) {
              if (requests && requests.length) {
                $rootScope.$broadcast(EVENT_REQUEST_CREATE, requests);
              }
            });
            break;
          case models.ROOM.MODEL:
            CreateRoomDialog.show({
              locals: locals,
            }).then(function (rooms) {
              if (rooms && rooms.length) {
                $rootScope.$broadcast(EVENT_ROOM_CREATE, rooms);
              }
            });
            break;
          case models.MAINTENANCE_SCHEDULE.MODEL:
            CreateScheduleDialog.show({
              locals: locals,
            }).then(function (schedules) {
              if (schedules && schedules.length) {
                $rootScope.$broadcast(EVENT_SCHEDULE_CREATE, schedules);
              }
            });
            break;
          case models.WORK_ORDER.MODEL:
            CreateWorkOrderDialog.show({
              locals: locals,
            }).then(function (workOrders) {
              if (workOrders && workOrders.length) {
                $rootScope.$broadcast(EVENT_WORK_ORDER_CREATE, workOrders);
              }
            });
            break;
          case models.INSPECTION_PROGRAM.MODEL:
            return CreateInspectionProgramDialog.show({ locals: locals })
              .then(function (inspectionProgram) {
                // need to re-fetch so we can get the assignees as well
                return InspectionProgramService.getById(
                  inspectionProgram.organization._id,
                  inspectionProgram._id
                );
              })
              .then(function (inspectionProgram) {
                $rootScope.$broadcast(
                  EVENT_INSPECTION_PROGRAM_CREATE,
                  inspectionProgram
                );
              });
          default:
            break;
        }
      }
    }

    /**
     * Handle when a valid QR code was scanned from the app header.
     *
     * - If the QR code has an associated model, go to its details.
     *
     * @param {Object} $event - AngularJS event object
     * @param {Object} data - Custom event data
     * @param {Object} data.qrCode - QR code model associated with the one
     *     scanned
     */
    function handleQrCodeScan($event, data) {
      var qrCode = data.qrCode;

      if (!qrCode.is_associated) {
        var locals = { qrCode: qrCode };
        return AssociateQrCodeDialog.show({ locals: locals })
          .then(handleAssociationEvent)
          .catch(function () {
            if ($state.is("app.qrCode")) {
              $state.go("app.dashboard");
            }
          });
      }

      var subdomain = self.organization.subdomain.key;
      var subdomainMatch = qrCode.redirect_url.match(/^[a-z0-9-]+/i);

      if (subdomainMatch && subdomainMatch[0] === subdomain) {
        if (qrCode.asset) {
          goToListViewDetail(qrCode.asset, "asset");
        } else if (qrCode.room) {
          goToListViewDetail(qrCode.room, "room");
        } else if (qrCode.task) {
          goToListViewDetail(qrCode.task, "task");
        }
      } else {
        $window.location.href =
          $location.protocol() + "://" + qrCode.redirect_url;
      }

      function goToListViewDetail(model, modelType, newTab) {
        var destinationState;
        var params = {};

        switch (modelType) {
          case "asset":
            destinationState = "app.asset";
            params.assetId = model._id;
            break;
          case "room":
            destinationState = "app.room";
            params.roomId = model._id;
            break;
          case "task":
            destinationState = "app.task";
            params.taskId = model._id;
            break;
        }

        if (destinationState) {
          var href = $state.href(destinationState, params);
          if (newTab) {
            $window.open(href, "_blank");
          } else {
            $window.open(href, "_self");
          }
        }
      }

      function handleAssociationEvent($event) {
        switch ($event.actionTaken) {
          case "search":
          case "create":
            var pin = $event.asset || $event.room;
            var pinModel = pin ? "asset" : "room";
            ToastService.complex()
              .text("Successfully associated")
              .action("Go to " + pinModel, function () {
                goToListViewDetail(pin, pinModel, true);
              })
              .show();
        }
      }
    }
  }
})();
