import { FunctionComponent } from "react";
import { stylesheet } from "./stylesheet";
import { AppBar, Toolbar, Button, Typography, Box } from "@mui/material";
import { MediaQuery } from "./mediaQuery";
import { getDomain } from "../utils/getDomain";
import { Color } from "./colors";

/**
 * React controlled NotFound component. Leverages MUI and Emotion,
 * with themes available for each via `WebClientApp`.
 *
 * MUI AppBar docs:
 * https://mui.com/material-ui/react-app-bar/
 *
 */
export const NotFound: FunctionComponent = () => {
  return (
    <>
      <Box css={ss.root}>
        <AppBar css={ss.toolbar} position="static">
          <Toolbar css={ss.toolbarRow} variant="regular">
            <Box css={ss.toolbarLeft}>
              <Button css={ss.button} variant="text" href={`//${getDomain()}`}>
                HOME
              </Button>
            </Box>
            <Box css={ss.toolbarCenter}>
              <a href="/">
                <img css={ss.abxImg} src="img/logo.png" />
              </a>
            </Box>
            <Box css={ss.toolbarRight}></Box>
          </Toolbar>
        </AppBar>
        <Box css={ss.content}>
          <Box css={ss.errorPage}>
            <Box css={ss.errorPageContent}>
              <Typography variant="h4" css={ss.headline}>
                We are having some trouble finding the page you are looking
                for...
              </Typography>
              <Box css={ss.imgContainer}>
                <img css={ss.img} src="img/error/404.svg" />
              </Box>
            </Box>
          </Box>
        </Box>
        <AppBar css={ss.footer} position="static">
          <Toolbar css={ss.footerContent} variant="regular">
            <a css={ss.footerLink} href="/privacy-policy">
              Privacy Policy
            </a>
            <a css={ss.footerLink} href="/terms-of-use">
              Terms of Use
            </a>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

const ss = stylesheet({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100%",
    height: "100%",
    backgroundColor: Color.White,
  },
  toolbar: {
    backgroundColor: Color.White,
    color: "rgba(0, 0, 0, 0.87)",
  },
  toolbarRow: {
    flexDirection: "row",
    display: "flex",
    fontWeight: 400,
    alignItems: "center",
    width: "100%",
    maxHeight: "64px",
  },
  toolbarLeft: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100%",
    maxHeight: "100%",
  },
  button: {
    fontSize: "14px",
    fontWeight: 700,
    backgroundColor: Color.White,
    color: "rgba(0,0,0,0.87)",
    ":hover": { backgroundColor: "rgba(158,158,158,0.2)", color: "inherit" },
  },
  toolbarCenter: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100%",
    maxHeight: "100%",
    alignItems: "stretch",
    textAlign: "center",
  },
  abxImg: {
    height: "36px",
  },
  toolbarRight: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100%",
    maxHeight: "100%",
  },
  content: {
    minHeight: 0,
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    overflow: "auto",
  },
  errorPage: (theme) => ({
    backgroundColor: Color.Blue,
    color: Color.White,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
  }),
  errorPageContent: (theme) => ({
    padding: theme.spacing(1),
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    height: "100%",
  }),
  headline: (theme) => ({
    textAlign: "center",
    padding: theme.spacing(1),
    fontFamily: "'Montserrat', sans-serif",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: "inherit",
  }),
  imgContainer: (theme) => ({
    padding: theme.spacing(1),
    flexDirection: "row",
    display: "flex",
    alignItems: "stretch",
    maxWidth: "100%",
    justifyContent: "center",
  }),
  img: {
    [MediaQuery.xs]: { width: "250px", height: "250px" },
    [MediaQuery.m]: { width: "480px", height: "480px" },
  },
  footer: {
    backgroundColor: Color.White,
    maxHeight: "64px",
  },
  footerContent: (theme) => ({
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    flexDirection: "row",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    fontWeight: 600,
    backgroundColor: Color.White,
  }),
  footerLink: (theme) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    maxHeight: "20px",
    position: "relative",
    color: "rgba(0,0,0,0.54)",
    textDecoration: "none",
    ":before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "1px",
      bottom: "-2px",
      left: 0,
      backgroundColor: Color.Black,
      visibility: "hidden",
      transform: "scaleX(0)",
      transition: "transform 0.3s ease-in-out 0s",
    },
    ":hover": {
      color: Color.Black,
      textDecoration: "none",
      ":before": {
        visibility: "visible",
        transform: "scaleX(1)",
      },
    },
  }),
});
