import CloseIcon from "@mui/icons-material/Close";
import { FunctionComponent, useState } from "react";
import {
  Box,
  Step,
  Dialog,
  Stepper,
  StepLabel,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";

import { Color } from "../colors";
import { BULK_ADD_STEPS } from "./steps";
import { BulkAddDialogProps } from "./BulkAddDialogTypes";
import { capitalizeText } from "../../utils/capitalizeText";

export const BulkAddDialog: FunctionComponent<BulkAddDialogProps> = ({
  model,
  onClose,
  organizationId,
  ...rest
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [importId, setImportId] = useState<string | null>(null);

  const { StepComponent, dialogWidth } = BULK_ADD_STEPS[activeStep];

  const handleNextStep = () => {
    const isLast = activeStep === BULK_ADD_STEPS.length - 1;
    if (isLast) {
      onClose?.();
    } else {
      setActiveStep((step) => step + 1);
    }
  };

  const handleImport = (importId: string) => setImportId(importId);

  return (
    <Dialog fullWidth disableEscapeKeyDown maxWidth={dialogWidth} {...rest}>
      <DialogTitle
        component={Box}
        css={{
          display: "flex",
          color: Color.White,
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: Color.MidnightExpress,
        }}
      >
        <Typography variant="h6" color="inherit">
          Bulk Add {capitalizeText(model)}
        </Typography>
        <IconButton color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          css={(theme) => ({ paddingTop: theme.spacing(4) })}
        >
          {BULK_ADD_STEPS.map(({ label }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <StepComponent
          model={model}
          importId={importId}
          onNext={handleNextStep}
          onImport={handleImport}
          organizationId={organizationId}
        />
      </DialogContent>
    </Dialog>
  );
};
