import { api } from "../api";
import { getSubdomain } from "../../utils/getSubdomain";

/**
 * Route loader function for fetching the feature flags,
 * organization by subdomain AND the user by organization
 *
 * @returns Object with `flags`, `organization` and `user` properties
 * @throws Error when any of the following happens:
 * - Fails to get feature flags
 * - Current subdomain doesn't have a associated organization
 * - No currently logged user for the retrieved organization
 */
export async function shellLoader() {
  const { data: flags } = await api.flags.get();

  const subdomain = getSubdomain();
  const { data: organizations } = await api.organizations.get({ subdomain });

  if (!organizations || !organizations.length) {
    throw new Error(`Unable to get organization for subdomain ${subdomain}`);
  }

  const [organization] = organizations;

  const { data: user } = await api.users.getByOrganization({
    organizationId: organization._id,
  });

  if (!user) {
    throw new Error(`Unable to get user for organization ${organization.name}`);
  }

  return { flags, organization, user };
}
