(function () {
  /**
   * @ngdoc component
   * @name abxAppHeader
   *
   * @param {Object} [building] - Selected building
   * @param {Object} [floor] - Selected floor
   * @param {Function} [onBuildingChange] - Building change callback
   * @param {Function} [onGroupChange] - Group change callback
   * @param {Function} [onFloorChange] - Floor change callback
   * @param {Function} [onAdd] - Callback to handle adding new rooms, assets,
   *    work orders, etc
   * @param {Function} [searchFetch] - Callback to return rooms and assets; must
   *    include pinTypes
   * @param {String|Object} [currentSearch] - Search binding passed down to
   *    abx-pin-search
   * @param {Function} [onPinSearchItemSelect] - Callback to handle abx-pin-search
   *    selection of a single room/asset
   * @param {Function} [onSearchIconClick] - Callback to handle abx-pin-search
   *    of multiple rooms/assets
   * @param {Boolean} [disableSearch] - Disable pin search bar
   * @param {Boolean} [disableQrCodeButton] - Disable QR Code button
   * @param {Boolean} [disableAddButton] - Disable add menu button
   * @param {Boolean} [disableAccountButton] - Disable account menu button
   * @param {String} [appTitle] - Title of the current app. Blocks using the updated app header styles (including App Switcher)
   *
   * @description
   * A header to display at the top of the application. Will display state information
   * that will depend on the application that the user is in.
   */
  angular
    .module("akitabox.ui.components.appHeader")
    .controller("AddMenuPanelController", AddMenuPanelController)
    .component("abxAppHeader", {
      bindings: {
        superText: "@?abxSuperText",
        primaryText: "@?abxPrimaryText",
        secondaryText: "@?abxSecondaryText",
        organization: "<?abxOrganization",
        group: "<?abxGroup",
        building: "<?abxBuilding",
        appTitle: "@?abxAppTitle",
        floor: "<?abxFloor",
        isOpen: "&?abxIsOpen",
        lockedOpen: "&?abxLockedOpen",
        onOrganizationChange: "&abxOnOrganizationChange",
        onGroupChange: "&abxOnGroupChange",
        onBuildingChange: "&abxOnBuildingChange",
        onFloorChange: "&?abxOnFloorChange",
        onAdd: "&?abxOnAdd",
        onOpen: "&?abxOnOpen",
        onClose: "&?abxOnClose",
        disableQrCodeButton: "<?abxDisableQrCodeButton",
        disableAddButton: "<?abxDisableAddButton",
        disableSwitcher: "<?abxDisableSwitcher",
        isMobile: "<?abxIsMobile",
        showBuildings: "<?abxShowBuildings",
      },
      controller: AbxAppHeaderController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/app-header/app-header.component.html",
      transclude: true,
    });

  /* @ngInject */
  function AbxAppHeaderController(
    // Angular
    $attrs,
    $scope,
    $rootScope,
    $location,
    $timeout,
    $window,
    // Third-party
    $state,
    ucfirstFilter,
    // Material
    $mdPanel,
    $mdMedia,
    // AkitaBox
    models,
    // Services
    IdentityService,
    BuildingService,
    DomService,
    EnvService,
    BannerMessageService,
    OrganizationService,
    RedirectService,
    UserService
  ) {
    var self = this;

    // Constants
    var DEFAULT_PRIMARY_TEXT =
      "Select a " + ucfirstFilter(models.BUILDING.SINGULAR);
    var DEFAULT_SECONDARY_TEXT =
      "Select a " + ucfirstFilter(models.FLOOR.SINGULAR);

    // Private Attributes
    var secondaryTextProvided = !angular.isEmpty($attrs.abxSecondaryText);
    var superTextProvided = !angular.isEmpty($attrs.abxSuperText);
    var userAccount;
    var currentUser = UserService.getCurrent();
    var permissions = UserService.getPermissions();

    if (currentUser) {
      userAccount = currentUser.identity;
    }

    // Attributes
    self.logo = {
      tooltip: "Dashboard",
      link: $state.href("app.dashboard"),
    };
    self.noSwitcher = angular.isDefined($attrs.abxNoSwitcher);
    self.noQrScanner = angular.isDefined($attrs.abxNoQrScanner);
    self.noAddButton = angular.isDefined($attrs.abxNoAddButton);
    self.lockedOpen = defaultExpression(self.lockedOpen, false);
    self.selectedGroup = null;
    self.switcherIsShown = self.isOpen ? self.isOpen() : false;
    self.isReadOnly = false;
    self.isMobile = angular.isDefined(self.isMobile) ? self.isMobile : false;
    self.loading = true;
    self.siteAdminUrl = EnvService.getSiteAdminUrl();
    self.accountSettingsUrl = EnvService.getAccountSettingsUrl() + "/settings";
    self.organizationCount = 0;
    self.addItems = [];
    self.isSuperAdmin = userAccount ? userAccount.is_super_admin : false;
    self.$rootScope = $rootScope;
    self.hasBuildings = false;
    self.bannerMessages = [];
    /**
     * TODO: Remove this when removing old header bar (non-app-switcher)
     * Replace all instances of this in "app-header.component.html" with "abx-app-header"
     * See "app-header.component.scss" for more TODOs related to this
     */
    self.baseClass = function () {
      return !self.isMobile ? "abx-updated-app-header" : "abx-app-header";
    };

    // Functions
    self.toggleSwitcher = toggleSwitcher;
    self.selectOrganization = selectOrganization;
    self.selectGroup = selectGroup;
    self.selectBuilding = selectBuilding;
    self.selectFloor = selectFloor;
    self.openAddMenu = openAddMenu;
    self.goToSiteAdmin = goToSiteAdmin;
    self.goToMobile = goToMobile;
    self.showDesktopSwitcher = showDesktopSwitcher;
    self.showMobileSwitcher = showMobileSwitcher;
    self.switcherDisabled = switcherDisabled;

    // =================
    // Life Cycle
    // =================

    self.$onInit = function () {
      setupHeaderText();
      checkForBuildings();
      getBannerMessages();

      IdentityService.getCurrent()
        .then(function (identity) {
          userAccount = identity;
          self.email = identity.email;
          self.display_name = identity.display_name;
          self.isSuperAdmin = identity.is_super_admin;
          if (!self.noSwitcher) {
            return getOrganizationCount();
          }
        })
        .finally(function () {
          self.addItems = getAddItems();
          self.loading = false;
        });

      if (!self.noSwitcher) {
        // Set switcher to initial state
        toggleSwitcher(self.switcherIsShown);
        // Watchers
        $scope.$watch("vm.isOpen()", function (isOpen) {
          if (self.switcherIsShown !== isOpen) {
            toggleSwitcher();
          }
        });
        $scope.$watch("vm.lockedOpen()", function (lockedOpen) {
          if (lockedOpen && !self.switcherIsShown) {
            toggleSwitcher(true);
          }
        });
      }
    };

    // Function to allow closing the header by clicking outside of it
    var target = !self.isMobile
      ? "abx-header-switcher"
      : "abx-mobile-header-switcher";
    var clickEvent = function (event) {
      if (!DomService.getParent(event.target, target, "abx-app-header")) {
        $scope.$apply(function () {
          toggleSwitcher(false);
        });
      }
    };

    // function to allow closing the header by pressing escape key
    var keyDownEvent = function (event) {
      if (event.key === "Escape" || event.key === "Esc") {
        $scope.$apply(function () {
          toggleSwitcher(false);
        });
      }
    };

    $scope.$watch("vm.switcherIsShown", function (newValue, oldValue) {
      // Only attach this event if there is already an org or building selected
      if (!self.selectedOrganization && !self.selectedBuilding) return;

      if (newValue && !oldValue) {
        // went from hidden to visible, attach the click event to allow users
        // to easily close the header-switcher
        // $timeout is used to avoid calling clickEvent on the initial click
        // that triggers this, we only want the subsequent clicks to call our
        // fn
        $timeout(function () {
          $window.document
            .getElementsByTagName("ABX-APP-HEADER")[0]
            .addEventListener("click", clickEvent);
          // Listen for the escape key to close the header
          $window.document.addEventListener("keydown", keyDownEvent);
        }, 0);
      } else if (!newValue && oldValue) {
        // went from visible to hidden
        // remove the click event and keydown event. Not needed when closed
        $window.document
          .getElementsByTagName("ABX-APP-HEADER")[0]
          .removeEventListener("click", clickEvent);
        $window.document.removeEventListener("keydown", keyDownEvent);
      }
    });

    self.$onChanges = function (changes) {
      if (changes.primaryText) {
        setPrimaryText(self.primaryText);
      }
      if (changes.secondaryText) {
        if (secondaryTextProvided) {
          setSecondaryText(self.secondaryText);
        }
      }
      if (changes.organization) {
        self.selectedOrganization = self.organization;
      }
      if (changes.group) {
        self.selectedGroup = self.group;
        if (self.group) {
          self.logo = {
            tooltip: self.group._id ? self.group.name : "Dashboard",
            link: $state.href("app.dashboard"),
          };
        }
      }
      if (changes.building) {
        self.selectedBuilding = self.building;
        if (self.building && !self.isMobile) {
          self.logo = {
            tooltip: "Building Dashboard",
            link: $state.href("app.building.detail", {
              buildingId: self.building._id,
            }),
          };
        }
      }
      if (!self.noSwitcher) {
        // Toggle switcher if mismatched
        if (self.switcherIsShown !== self.isOpen()) {
          toggleSwitcher();
        }
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Toggle switcher visibility, optionally set visibility explicitly
     *
     * @param {Boolean} [open]  Should the switcher be open
     */
    function toggleSwitcher(open) {
      // If the switcher is locked, keep it open
      if (self.lockedOpen()) {
        self.switcherIsShown = true;
        return;
      }
      var changed = false;
      if (angular.isDefined(open)) {
        changed = self.switcherIsOpen !== open;
        self.switcherIsShown = open;
      } else {
        // Toggle the current visibility
        self.switcherIsShown = !self.switcherIsShown;
        changed = true;
      }

      if (changed) {
        if (self.switcherIsShown && self.onOpen) {
          self.onOpen();
        } else if (self.onClose) {
          self.onClose();
        }
      }

      if (!self.switcherIsShown) {
        self.selectedBuilding = BuildingService.getCurrent();
        self.selectedOrganization = OrganizationService.getCurrent();
      }
    }

    /**
     * Redirect to the Admin Client page
     */
    function goToSiteAdmin() {
      var siteAdminUrl = EnvService.getSiteAdminUrl();
      $window.open(siteAdminUrl, "_self");
    }

    /**
     * Open the mobile site
     */
    function goToMobile() {
      RedirectService.redirectToMobile($location.path());
    }

    function selectOrganization($event) {
      self.selectedGroup = null;
      self.selectedBuilding = null;
      self.selectedOrganization = $event.model;
      self.onOrganizationChange({ $event: $event });
    }

    /**
     * Select the given group and notify the parent
     *
     * @param {Array}  group - a list of buildings
     * @param {Object} params - additional parameters
     */
    function selectGroup($event) {
      if (self.selectedGroup && self.selectedGroup._id === $event.model._id) {
        return toggleSwitcher(false);
      }
      self.selectedGroup = $event.model;
      self.selectedBuilding = null;
      self.onGroupChange({ $event: $event });
    }

    /**
     * Select the given building and notify parents
     *
     * @param {Object} building - the selected building
     */
    function selectBuilding($event) {
      var floorRequired = !!self.onFloorChange;
      var sameBuilding =
        self.selectedBuilding &&
        $event &&
        $event.model &&
        self.selectedBuilding._id === $event.model._id;

      self.selectedGroup = null;
      self.selectedBuilding = $event.model;
      if (!sameBuilding) {
        self.onBuildingChange({ $event: $event });
      }

      if (!floorRequired) {
        return toggleSwitcher(false);
      }
    }

    /**
     * Select the given floor and notify parents
     *
     * @param {Object} floor  the floor chosen in the header switcher
     */
    function selectFloor(floor, newTab) {
      if (self.floor && self.floor._id === floor._id) {
        return toggleSwitcher(false);
      }
      self.onFloorChange({
        $event: {
          organization: self.selectedOrganization,
          building: self.selectedBuilding,
          floor: floor,
          newTab: newTab,
        },
      });
    }

    /**
     * Open the add menu that allows a user to add a new work order,
     * service request, maintenance schedule, floor, room, asset, or file
     *
     * @param {Event} $event  Event that triggers the open action
     */
    function openAddMenu($event) {
      var targetElement = "." + self.baseClass() + "__add-button";
      var position = $mdPanel
        .newPanelPosition()
        .relativeTo(targetElement)
        .addPanelPosition(
          $mdPanel.xPosition.ALIGN_END,
          $mdPanel.yPosition.BELOW
        )
        .withOffsetX("-8px")
        .withOffsetY("-4px");
      var animation = $mdPanel
        .newPanelAnimation()
        .openFrom(targetElement)
        .duration(150)
        .closeTo(targetElement)
        .withAnimation($mdPanel.animation.FADE);
      var panelClass = "app-header-add-menu";
      panelClass += " app-header-add-menu--" + self.addItems.length;

      var config = {
        attachTo: angular.element(document.body),
        controller: "AddMenuPanelController",
        controllerAs: "vm",
        bindToController: true,
        templateUrl: "app/core/ui/components/app-header/add-menu.template.html",
        panelClass: panelClass,
        position: position,
        animation: animation,
        locals: {
          onAdd: self.onAdd,
          items: self.addItems,
        },
        openFrom: $event,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: false,
        zIndex: 20,
      };
      $mdPanel.open(config);
    }

    function switcherDisabled() {
      return self.noSwitcher || self.disableSwitcher;
    }

    function showDesktopSwitcher() {
      return !self.switcherDisabled() && !self.isMobile;
    }

    function showMobileSwitcher() {
      return !self.switcherDisabled() && self.isMobile;
    }

    // =================
    // Private Functions
    // =================

    function getOrganizationCount() {
      return OrganizationService.get({ count: true }).then(function (result) {
        self.organizationCount = result.count;
        self.noSwitcher = result.count === 0;
      });
    }

    /**
     * Default an expression binding
     * Expressions are functions therefore a default needs to also be a function
     */
    function defaultExpression(binding, defaultValue) {
      if (!binding) {
        return function () {
          return defaultValue;
        };
      }
      return binding;
    }

    /**
     * Setup the main text of the app header
     */
    function setupHeaderText() {
      // Set header text
      setPrimaryText(self.primaryText); // set to default if null
      if (secondaryTextProvided) {
        setSecondaryText(self.secondaryText);
      }
      if (superTextProvided) {
        setSuperText(self.superText);
      }
    }

    /**
     * Ensuring user has access to at least 1 building
     */
    function checkForBuildings() {
      var params = {
        archive_date: "null",
        count: true,
      };
      BuildingService.getAll(params).then(function (buildings) {
        if (buildings.count > 0) {
          self.hasBuildings = true;
        }
      });
    }

    /**
     *
     */
    function getBannerMessages() {
      if (!self.organization) {
        return;
      }
      BannerMessageService.getByOrganization(self.organization._id).then(
        function (bannerMessages) {
          self.bannerMessages = bannerMessages;
        }
      );
    }

    /**
     * Sets the primary text if not empty, else sets to default
     *
     * @param {String} text New primary header text
     */
    function setPrimaryText(text) {
      self.primaryText = angular.isEmpty(text) ? DEFAULT_PRIMARY_TEXT : text;
    }

    /**
     * Sets the secondary text if not empty, else sets to default
     *
     * @param {String} text New secondary header text
     */
    function setSecondaryText(text) {
      self.secondaryText = angular.isEmpty(text)
        ? DEFAULT_SECONDARY_TEXT
        : text;
    }

    /**
     * Sets the super text if not empty, else sets to default
     *
     * @param {String} text New super text
     */
    function setSuperText(text) {
      if (!angular.isEmpty(text)) {
        self.superText = text;
      }
    }

    function getAddItems() {
      if (!self.organization) {
        return [];
      }
      var requestOption = {
        model: models.SERVICE_REQUEST.MODEL,
        text: models.SERVICE_REQUEST.SINGULAR.split(" ").join("<br />"),
        icon: "feedback",
      };
      var workOrderOption = {
        model: models.WORK_ORDER.MODEL,
        text: models.WORK_ORDER.SINGULAR.split(" ").join("<br />"),
        icon: "build",
      };
      var scheduleOption = {
        model: models.MAINTENANCE_SCHEDULE.MODEL,
        text: models.MAINTENANCE_SCHEDULE.SINGULAR.split(" ").join("<br />"),
        icon: "event_available",
      };
      var floorOption = {
        model: models.FLOOR.MODEL,
        text: models.FLOOR.SINGULAR + "<br />&nbsp;",
        icon: "layers",
      };
      var roomOption = {
        model: models.ROOM.MODEL,
        text: models.ROOM.SINGULAR + "<br />&nbsp;",
        icon: "dashboard",
      };
      var assetOption = {
        model: models.ASSET.MODEL,
        text: models.ASSET.SINGULAR + "<br />&nbsp;",
        icon: "place",
      };
      var fileOption = {
        model: models.DOCUMENT.MODEL,
        text: models.DOCUMENT.SINGULAR + "<br />&nbsp;",
        icon: "folder_open",
      };
      var inspectionProgramOption = {
        model: models.INSPECTION_PROGRAM.MODEL,
        text: "Inspection<br />Program",
        icon: "dns",
      };

      var options = [];
      if (self.organization.show_tasks) {
        options = !self.organization.third_party_cmms_reactive_wos
          ? [requestOption, workOrderOption, scheduleOption]
          : [workOrderOption];
      }
      Array.prototype.push.apply(options, [
        floorOption,
        roomOption,
        assetOption,
        fileOption,
      ]);
      if (self.organization.show_inspections) {
        options.push(inspectionProgramOption);
      }

      return options.filter(function (item) {
        if (item.model === models.FLOOR.MODEL) {
          return permissions.is_administrator;
        }
        return permissions[item.model].create;
      });
    }
  }

  /**
   * Controller of the add menu panel which handles the selection of a
   * model the user desires to add
   *
   * @param {Object} mdPanelRef   Panel reference
   */
  function AddMenuPanelController(mdPanelRef) {
    var self = this;

    // Functions
    self.addItem = addItem;

    // =================
    // Public Functions
    // =================

    function addItem(item) {
      self.onAdd({ $event: { model: item.model } });
      mdPanelRef && mdPanelRef.close();
    }
  }
})();
