(function () {
  angular
    .module("akitabox.desktop.room.list")
    .controller("RoomListController", RoomListController);

  /* @ngInject */
  function RoomListController(
    // Angular
    $scope,
    $timeout,
    $location,
    $q,
    // Constants
    EVENT_ROOM_CREATE,
    // Dialogs
    CreateRoomDialog,
    ExportDialog,
    // Services
    RoomService,
    PinService,
    PinTypeService,
    ToastService,
    FilterBarManager,
    ManagedModelFieldFilter,
    ManagedEnumPinValueFilter,
    ManagedLevelFilter,
    FeatureFlagService,
    ManagedFilterHelpers,
    OrganizationService,
    SessionService,
    // Resolve
    building,
    decommissioned
  ) {
    var self = this;

    // Attributes
    self.rooms = null;
    self.building = building;
    self.decommissioned = decommissioned;
    self.organization = OrganizationService.getCurrent();
    self.pinTypes = [];
    self.classes = { sidenav: "room-list-sidenav" };
    self.hiddenActions = {
      move: true,
      delete: true,
      duplicate: true,
      overviewLink: true,
      planViewLink: false,
    };

    self.subtitle = { name: "Rooms" };
    self.actions = getDefaultHeaderActions();
    self.fabActions = getFabActions();
    self.activeFilters = null;
    self.selectedRoom = null;
    self.isListViewSidebarEnabled =
      FeatureFlagService.isEnabled("listview_sidebar") || false;
    self.showSidebar = SessionService.getRoomListSidebarToggleState() || false;

    // Functions
    self.addRoom = addRoom;
    self.changeFilters = changeFilters;
    self.fetchRooms = fetchRooms;
    self.fetchAllRooms = fetchAllRooms;
    self.clearSelectedRoom = clearSelectedRoom;
    self.updateSelectedRoom = updateSelectedRoom;
    self.getRoomPinTypeId = getRoomPinTypeId;
    self.roomPinTypeId = undefined;

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // apply the static ?decommissioned=${decommissioned} portion of the query
        query.decommissioned = decommissioned;
        // update the list
        changeFilters(query);
      },
    });

    var getBuildingId = function () {
      return self.building._id;
    };

    var numberConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Number",
      queryField: "number",
    });

    var levelOptions = {
      getBuildingId: function () {
        return self.building._id;
      },
    };
    var levelConfig = new ManagedLevelFilter(
      self.filterBarManager,
      levelOptions
    );
    var sqftConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Square Footage",
      pinFieldName: "Square Feet",
      inputType: "number-range",
      queryField: "square_feet",
      modelValueToFilterValue:
        ManagedFilterHelpers.numberRangeModelValueToFilterValue,
      modelValueToChipText:
        ManagedFilterHelpers.numberRangeModelValueToChipText,
      filterValueToModelValue:
        ManagedFilterHelpers.numberRangeFilterValueToModelValue,
    });
    var placedOnFloorConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Placed on Floor Plan",
        queryField: "percentX",
        inputType: "boolean",
        modelValueToFilterValue: function (placedOnFloor) {
          return placedOnFloor ? "$ne,null" : "null";
        },
        modelValueToChipText: function (placedOnFloor) {
          return placedOnFloor ? "Yes" : "No";
        },
        filterValueToModelValue: function (queryParameter) {
          return [queryParameter === "$ne,null"];
        },
      }
    );
    var nameConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Name",
      queryField: "name",
    });

    var decommissionedDateConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Decommissioned Date",
        queryField: "decommissioned_date",
        inputType: "date-range",
        modelValueToFilterValue:
          ManagedFilterHelpers.dateModelValueToFilterValue,
        modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
        filterValueToModelValue:
          ManagedFilterHelpers.dateFilterValueToModelValue,
      }
    );

    var roomTypeConfig = new ManagedEnumPinValueFilter(self.filterBarManager, {
      getBuildingId: getBuildingId,
      displayName: "Room Type",
      pinFieldName: "Type", // selects only pin fields with name "Type"
      placeholder: "Choose a room type",
      protected_type: "Room", // restricts the pin type query to just room pin types
    });
    var floorTypeConfig = new ManagedEnumPinValueFilter(self.filterBarManager, {
      getBuildingId: getBuildingId,
      displayName: "Floor Type",
      pinFieldName: "Floor Type", // selects only pin fields with name "Type"
      placeholder: "Choose a floor type",
      protected_type: "Room", // restricts the pin type query to just room pin types
    });
    self.filterBarManager.addFilterConfiguration(nameConfig);
    self.filterBarManager.addFilterConfiguration(numberConfig);
    self.filterBarManager.addFilterConfiguration(placedOnFloorConfig);
    self.filterBarManager.addFilterConfiguration(floorTypeConfig);
    self.filterBarManager.addFilterConfiguration(roomTypeConfig);
    self.filterBarManager.addFilterConfiguration(sqftConfig);
    self.filterBarManager.addFilterConfiguration(levelConfig);
    // only show the decommissioned_date filter if
    // user is on the decommissioned sub-list
    if (decommissioned === "true") {
      self.filterBarManager.addFilterConfiguration(decommissionedDateConfig);
    }

    self.filterInitPromise = self
      .getRoomPinTypeId()
      .then(function (_pinTypeId) {
        self.roomPinTypeId = _pinTypeId;
        return self.filterBarManager.applyQuery($location.search());
      });

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_ROOM_CREATE, onRoomCreate);
    $scope.$on("room_selected", onRoomSelection);
    $scope.$on("close_sidebar", clearSelectedRoom);
    $scope.$watch("vm.showSidebar", onToggleSidebar);

    // ------------------------
    //   Private Functions
    // ------------------------

    function getDefaultHeaderActions() {
      return [
        {
          icon: "get_app",
          text: "Export",
          onClick: exportRooms,
        },
      ];
    }

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportRooms,
        },
      ];
    }

    function exportRooms() {
      var locals = {
        route: RoomService.buildListRoute(building._id),
        filters: self.activeFilters,
        excelOnly: true,
      };
      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    /**
     * Fetch the room pin type for the building
     * @return { Promise<string> } The pin type ID
     */
    function getRoomPinTypeId() {
      return PinTypeService.get(self.building._id, {
        protected_type: "Room",
      }).then(function (pinTypes) {
        self.pinTypes = pinTypes;
        return pinTypes[0]._id;
      });
    }

    /**
     * Handle room creation event
     *
     * @param {Event}   $event    Angular event
     * @param {Room[]} rooms    List of new rooms
     */
    function onRoomCreate($event, rooms) {
      // We can only create one room at a time
      var room = rooms[0];
      const building = room.building._id || room.building;
      if (building === self.building._id) {
        PinService.includePinType(room.building, [room]).then(function (
          results
        ) {
          if (!self.rooms) {
            self.rooms = [];
          }
          self.rooms.unshift(results[0]);
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        });
      }
    }

    function onToggleSidebar() {
      SessionService.setRoomListSidebarToggleState(self.showSidebar);
    }

    function onRoomSelection(_event, room) {
      const shouldShowSidebar =
        self.isListViewSidebarEnabled && self.showSidebar;
      if (shouldShowSidebar && room) {
        RoomService.getById(
          room.building._id || room.building,
          room._id,
          { include_values: true },
          { includePinType: true, includeFloor: true }
        )
          .then((model) => {
            self.selectedRoom = model;
          })
          .catch(ToastService.showError);
      } else {
        self.selectedRoom = null;
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------
    function clearSelectedRoom() {
      self.selectedRoom = null;
    }

    function updateSelectedRoom($event) {
      const { field, pinField, newValue } = $event;
      const { _id, values, building } = self.selectedRoom;
      if (field === "values") {
        const pinValue = values[pinField._id];
        RoomService.updateValueByDataType(
          building,
          pinField.data_type,
          _id,
          pinValue._id,
          newValue,
          { pin: self.selectedRoom }
        ).catch(ToastService.showError);
      } else {
        RoomService.updateById(
          building,
          _id,
          { [field]: newValue },
          {},
          self.selectedRoom
        ).catch(ToastService.showError);
      }
    }

    function addRoom() {
      CreateRoomDialog.show({ locals: { building: building } })
        .then(function (rooms) {
          // We know we're only getting one back, so take the first item in the returned array
          var newRoom = rooms[0];

          if (angular.isArray(self.rooms)) {
            self.rooms.unshift(newRoom);
          } else {
            self.rooms = [newRoom];
          }
          $timeout(function () {
            $scope.$broadcast("list:updateSelectedIndices", rooms.length);
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function fetchRooms(skip, limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(
          self.activeFilters,
          "decommissioned"
        )
      ) {
        $location.search("decommissioned", decommissioned);
      }
      var doFetch = function () {
        var params;
        params = {
          skip: skip,
          limit: limit,
          include_values: true,
          decommissioned: decommissioned,
          building: building._id,
        };

        var options = {
          includePinType: true,
        };
        var filters = angular.extend({}, params, self.activeFilters);

        return RoomService.getByBFFRoomsOrganization(
          self.organization._id,
          filters,
          options
        ).then(function (rooms) {
          if (angular.isArray(self.rooms)) {
            self.rooms = self.rooms.concat(rooms);
          } else {
            self.rooms = rooms;
          }
          return rooms.length;
        });
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchAllRooms(limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(
          self.activeFilters,
          "decommissioned"
        )
      ) {
        $location.search("decommissioned", decommissioned);
      }
      var doFetch = function () {
        var params;
        params = {
          skip: 0,
          limit: limit,
          include_values: true,
          decommissioned: decommissioned,
        };

        var options = {
          includePinType: true,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return RoomService.getAll(building._id, filters, options).then(
          function (rooms) {
            self.rooms = rooms;
            return rooms.length;
          }
        );
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.rooms = null;
      $scope.$broadcast("list:refresh");
    }
  }
})();
