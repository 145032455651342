(function () {
  angular
    .module("akitabox.desktop.asset.organizationList")
    .controller(
      "OrganizationAssetListController",
      OrganizationAssetListController
    );

  /* @ngInject */
  function OrganizationAssetListController(
    // Angular
    $scope,
    $timeout,
    $location,
    $q,
    // Constants
    EVENT_ASSET_CREATE,
    // Dialogs
    ExportDialog,
    // Services
    AssetService,
    OrganizationService,
    PinService,
    ToastService,
    FeatureFlagService,
    SessionService,
    // Filtering
    FilterBarManager,
    ManagedModelFieldFilter,
    ManagedBuildingFilter,
    ManagedFilterHelpers,
    // Resolve
    decommissioned
  ) {
    var self = this;

    var organization = OrganizationService.getCurrent();
    // Attributes
    self.organization = organization;
    self.assets = null;
    self.decommissioned = decommissioned;
    self.fabActions = getFabActions();
    self.activeFilters = null;
    self.selectedAsset = null;
    self.classes = { sidenav: "org-asset-list-sidenav" };
    self.isListViewSidebarEnabled =
      FeatureFlagService.isEnabled("listview_sidebar") || false;
    self.showSidebar = SessionService.getAssetListSidebarToggleState() || false;
    self.hiddenActions = {
      move: true,
      delete: true,
      duplicate: true,
      overviewLink: true,
      planViewLink: false,
    };

    // Functions
    self.changeFilters = changeFilters;
    self.fetchAssets = fetchAssets;
    self.fetchAllAssets = fetchAllAssets;
    self.clearSelectedAsset = clearSelectedAsset;
    self.updateSelectedAsset = updateSelectedAsset;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_ASSET_CREATE, onAssetCreate);
    $scope.$on("asset_selected", onAssetSelection);
    $scope.$on("close_sidebar", clearSelectedAsset);
    $scope.$watch("vm.showSidebar", onToggleSidebar);

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // apply the static ?decommissioned=${decommissioned} portion of the query
        query.decommissioned = decommissioned;

        // update the list
        changeFilters(query);
      },
    });

    const show_fca = self.organization && self.organization.show_fca;

    var nameConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Name",
      queryField: "name",
    });

    var placedOnFloorConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Placed on Floor Plan",
        queryField: "percentX",
        inputType: "boolean",
        modelValueToFilterValue: function (placedOnFloor) {
          return placedOnFloor ? "$ne,null" : "null";
        },
        modelValueToChipText: function (placedOnFloor) {
          return placedOnFloor ? "Yes" : "No";
        },
        filterValueToModelValue: function (queryParameter) {
          return [queryParameter === "$ne,null"];
        },
      }
    );

    var buildingConfig = new ManagedBuildingFilter(self.filterBarManager, {
      getOrganizationId: function () {
        return self.organization._id;
      },
    });

    self.filterBarManager.addFilterConfiguration(nameConfig);
    if (show_fca) {
      var totalCostConfig = new ManagedModelFieldFilter(self.filterBarManager, {
        displayName: "Total Cost",
        queryField: "costlines.total_cost_with_adjustments",
        inputType: "float-range",
        modelValueToChipText:
          ManagedFilterHelpers.numberRangeModelValueToChipText,
        modelValueToFilterValue:
          ManagedFilterHelpers.numberRangeModelValueToFilterValue,
        filterValueToModelValue:
          ManagedFilterHelpers.numberRangeFilterValueToModelValue,
      });
      self.filterBarManager.addFilterConfiguration(totalCostConfig);
    }
    self.filterBarManager.addFilterConfiguration(placedOnFloorConfig);
    self.filterBarManager.addFilterConfiguration(buildingConfig);

    if (FeatureFlagService.isEnabled("root_asset_size")) {
      const sizeConfig = new ManagedModelFieldFilter(self.filterBarManager, {
        displayName: "Size",
        queryField: "size",
        inputType: "float-range",
        modelValueToChipText:
          ManagedFilterHelpers.numberRangeModelValueToChipText,
        modelValueToFilterValue:
          ManagedFilterHelpers.numberRangeModelValueToFilterValue,
        filterValueToModelValue:
          ManagedFilterHelpers.numberRangeFilterValueToModelValue,
      });
      self.filterBarManager.addFilterConfiguration(sizeConfig);

      const sizeUnitConfig = new ManagedModelFieldFilter(
        self.filterBarManager,
        {
          displayName: "Size Unit",
          queryField: "size_unit",
        }
      );
      self.filterBarManager.addFilterConfiguration(sizeUnitConfig);
    }

    if (self.organization.show_installation_date_field) {
      const installationDateConfig = new ManagedModelFieldFilter(
        self.filterBarManager,
        {
          displayName: "Installation Date",
          queryField: "installation_date",
          inputType: "number-range",
          modelValueToChipText:
            ManagedFilterHelpers.numberRangeModelValueToChipText,
          modelValueToFilterValue: (yearRange) => {
            const parts = [];
            if (yearRange && yearRange.start) {
              parts.push("$gte", new Date(yearRange.start, 0, 0).getTime());
            }
            if (yearRange && yearRange.end) {
              parts.push("$lte", new Date(yearRange.end + 1, 0, 0).getTime());
            }
            return parts.join(",");
          },
          filterValueToModelValue: (queryStringValue) => {
            const yearRange = {};
            if (queryStringValue && queryStringValue.length > 0) {
              const parts = queryStringValue.split(",");
              while (parts.length > 0 && parts.length % 2 === 0) {
                const operation = parts.shift();
                const value = parts.shift();
                const date = new Date(Number(value));
                if (operation === "$gte") {
                  yearRange.start = date.getUTCFullYear() + 1;
                } else if (operation === "$lte") {
                  yearRange.end = date.getUTCFullYear();
                }
              }
            }
            return [yearRange];
          },
        }
      );
      self.filterBarManager.addFilterConfiguration(installationDateConfig);
    }

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // ------------------------
    //   Private Functions
    // ------------------------

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportAssets,
        },
      ];
    }

    function exportAssets() {
      var locals = {
        route: AssetService.buildOrganizationListRoute(organization._id),
        filters: self.activeFilters,
        excelOnly: true,
      };
      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    /**
     * Handle asset creation event
     *
     * @param {Event}   $event    Angular event
     * @param {Asset[]} assets    List of new assets
     */
    function onAssetCreate($event, assets) {
      // We can only create one asset at a time
      var asset = assets[0];
      PinService.includePinType(asset.building, [asset]).then(function (
        results
      ) {
        if (!self.assets) {
          self.assets = [];
        }
        self.assets.unshift(results[0]);
        $timeout(function () {
          $scope.$broadcast("list:refreshClickEvents");
        });
      });
    }

    function onToggleSidebar() {
      SessionService.setAssetListSidebarToggleState(self.showSidebar);
    }

    function onAssetSelection(_event, asset) {
      const shouldShowSidebar =
        self.isListViewSidebarEnabled && self.showSidebar;
      if (shouldShowSidebar && asset) {
        AssetService.getById(
          asset.building,
          asset._id,
          { include_values: true },
          { includePinType: true, includeFloor: true, includeRoom: true }
        )
          .then((model) => {
            self.selectedAsset = model;
          })
          .catch(ToastService.showError);
      } else {
        self.selectedAsset = null;
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------
    function clearSelectedAsset() {
      self.selectedAsset = null;
    }

    function updateSelectedAsset($event) {
      const { field, pinField, newValue } = $event;
      const { _id, values, building } = self.selectedAsset;
      if (field === "values") {
        const pinValue = values[pinField._id];
        AssetService.updateValueByDataType(
          building,
          pinField.data_type,
          _id,
          pinValue._id,
          newValue,
          { pin: self.selectedAsset }
        ).catch(ToastService.showError);
      } else {
        AssetService.updateById(
          building,
          _id,
          { [field]: newValue },
          {},
          self.selectedAsset
        ).catch(ToastService.showError);
      }
    }

    function fetchAssets(skip, limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(
          self.activeFilters,
          "decommissioned"
        )
      ) {
        $location.search("decommissioned", decommissioned);
      }

      var doFetch = function () {
        var params;
        params = {
          skip: skip,
          limit: limit,
          include_values: true,
          decommissioned: decommissioned,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        const setAssets = function (assets) {
          let asset = updateAssetsProps(assets);
          self.assets = angular.isArray(self.assets)
            ? self.assets.concat(asset)
            : asset;
          return assets.length;
        };

        return AssetService.getByBFFOrganization(
          self.organization._id,
          filters
        ).then(setAssets);
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function updateAssetsProps(assets) {
      return assets.map((asset) => {
        const building = asset.building;
        if (building && building._id && building._id) {
          asset.building = building._id;
          asset.building_name = building.name;
        }
        if (asset.room) asset.room.value = asset.room._id;
        if (asset.level) asset.level.value = asset.level._id;
        return {
          ...asset,
        };
      });
    }

    function fetchAllAssets(limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(
          self.activeFilters,
          "decommissioned"
        )
      ) {
        $location.search("decommissioned", decommissioned);
      }

      var doFetch = function () {
        var params;
        params = {
          skip: 0,
          limit: limit,
          include_values: true,
          decommissioned: decommissioned,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        const setAssets = function (assets) {
          let asset = updateAssetsProps(assets);
          self.assets = angular.isArray(self.assets)
            ? self.assets.concat(asset)
            : asset;
          return assets.length;
        };

        return AssetService.getByBFFOrganization(
          self.organization._id,
          filters
        ).then(setAssets);
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.assets = null;
      $scope.$broadcast("list:refresh");
    }
  }
})();
