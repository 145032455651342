(function () {
  angular
    .module("akitabox.core.auth.register")
    .controller("RegisterController", RegisterController)
    .controller("TermsOfUseController", TermsOfUseController);

  /* @ngInject */
  function RegisterController(
    $window,
    // Third-party
    $state,
    $stateParams,
    // Material
    $mdDialog,
    // Constants
    PASSWORD_REQUIREMENTS_PATTERN,
    // Services
    AuthService
  ) {
    // check state params and display proper tab first
    var self = this;

    var generalErrorMsg = "Sorry, we weren't able to complete your request.";

    // Attributes
    self.loading = false;
    self.PASSWORD_REQUIREMENTS_PATTERN = PASSWORD_REQUIREMENTS_PATTERN;

    self.showNext = true;
    self.user = {
      email: $stateParams.email,
      password: null,
      firstName: null,
      lastName: null,
      companyName: null,
      title: null,
    };

    self.errorMessage = null;
    self.showPasswordForm = false;
    self.showEmailForm = true;

    // Handlers
    self.checkSAML = checkSAML;
    self.showTerms = showTerms;
    self.escapeRegex = escapeRegex;

    // ------------------------
    //   Life cycle
    // ------------------------

    // ------------------------
    //   Punlic Functions
    // ------------------------

    function checkSAML() {
      if (!self.user.email) {
        return;
      }

      self.loading = true;
      AuthService.federate(self.user.email)
        .then(function (response) {
          if (response && response.redirect) {
            // redirect user to SAML login
            var prefix = response.redirect.indexOf("//") >= 0 ? "" : "//";
            $window.location.href = prefix + response.redirect;
          } else {
            self.showPasswordForm = true;
            self.showNext = false;
            self.adErrorMessage = null;
          }
        })
        .catch(handleError)
        .finally(function () {
          // show the email form if we are not showing the passwordForm
          self.showEmailForm = !self.showPasswordForm;
          self.loading = false;
        });
    }

    function handleError(response) {
      var message = generalErrorMsg;
      if (
        response &&
        response.data &&
        response.data.error &&
        response.data.error.message
      ) {
        message = response.data.error.message;
      }

      self.adErrorMessage = message;
    }

    function createAccount() {
      self.loading = true;
      AuthService.register(self.user.email, self.user.password, {
        firstName: self.user.firstName,
        lastName: self.user.lastName,
        companyName: self.user.companyName,
        title: self.user.title,
      })
        .then(function (data) {
          if (data && data.account) {
            $state.go("auth.sendValidation", { email: data.account.email });
          } else {
            self.errorMessage = generalErrorMsg;
          }
        })
        .catch(function (response) {
          var message = generalErrorMsg;
          if (
            response &&
            response.data &&
            response.data.error &&
            response.data.error.message
          ) {
            message = response.data.error.message;
          }

          self.errorMessage = message;
        })
        .finally(function () {
          self.loading = false;
        });
    }

    function showTerms() {
      $mdDialog
        .show({
          templateUrl:
            "app/core/modules/auth/register/terms-of-use.dialog.html",
          controller: "TermsOfUseController",
          controllerAs: "vm",
          fullscreen: true,
        })
        .then(function () {
          // They hit agree
          self.errorMessage = null;
          createAccount();
        })
        .catch(function () {
          // They hit disagree
          self.errorMessage = "You must agree to the Terms of Use to continue";
        });
    }

    function escapeRegex(password) {
      if (angular.isEmpty(password)) return "^$";

      return angular
        .copy(password)
        .replace(/[-[\]{}()*+?/.,\\^$|#@\s]/g, "\\$&");
    }
  }

  /* @ngInject */
  function TermsOfUseController($mdDialog) {
    var self = this;

    // Attributes
    self.checkAgreed = false;

    // Functions
    self.agree = $mdDialog.hide;
    self.disagree = $mdDialog.cancel;
  }
})();
