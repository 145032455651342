import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { FunctionComponent, useState } from "react";
import Close from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import Save from "@mui/icons-material/Save";
import { ReportBlockResponse } from "@akitabox/api-client";
import { Color } from "../../colors";
import {
  ReportBlockSelectionDataGrid,
  ReportBlockSelectionDataGridFilters,
} from "../../report-block-dialog/ReportBlockSelectionDataGrid";

export interface ReportBlockPromptDialogProps extends DialogProps {
  organizationId: string;
  handleClose: (
    action: "close" | "insert",
    description: string,
    reportBlock: ReportBlockResponse | null,
    reportBlockFilters: ReportBlockSelectionDataGridFilters
  ) => Promise<void>;
}

export const ReportBlockPromptDialog: FunctionComponent<
  ReportBlockPromptDialogProps
> = ({ organizationId, handleClose, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [promptDescription, setPromptDescription] = useState("");
  const [promptReportBlock, setPromptReportBlock] =
    useState<ReportBlockResponse | null>(null);
  const [promptFilters, setPromptFilters] =
    useState<ReportBlockSelectionDataGridFilters>({});

  const resetState = () => {
    setPromptDescription("");
    setPromptReportBlock(null);
    setPromptFilters({});
  };

  return (
    <Dialog {...props}>
      <DialogTitle
        css={{ backgroundColor: Color.MidnightExpress, color: Color.White }}
        component="h6"
      >
        Insert Report Block Prompts
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={() => {
            resetState();
            handleClose("close", "", null, []);
          }}
          css={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          css={(theme) => ({
            display: "flex",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          })}
          label="Prompt Description"
          value={promptDescription}
          onChange={(e) => setPromptDescription(e.target.value)}
          required
        />
        <ReportBlockSelectionDataGrid
          organizationId={organizationId}
          selectedReportBlock={promptReportBlock}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setSelectedAssets={() => {}}
          setSelectedReportBlock={setPromptReportBlock}
          selectable={false}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setSelectedBuildings={() => {}}
          onFilterChange={(filter) => {
            setPromptFilters(filter);
          }}
          disabledColumns={{ building: true }}
        />
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={() => {
              resetState();
              handleClose("close", "", null, []);
            }}
            disabled={loading}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<Save />}
            variant="contained"
            color="primary"
            disabled={loading || !promptReportBlock || !promptDescription}
            onClick={async () => {
              setLoading(true);

              try {
                await handleClose(
                  "insert",
                  promptDescription,
                  promptReportBlock,
                  promptFilters
                );
                resetState();
              } finally {
                setLoading(false);
              }
            }}
          >
            Insert
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
