import { Stack } from "@mui/material";
import {
  useRef,
  useMemo,
  useState,
  Dispatch,
  useEffect,
  useCallback,
  SetStateAction,
  FunctionComponent,
} from "react";
import {
  PinTypeResponse,
  ReportBlockResponse,
  WebReportBlockBuildingResponse,
  WebAssetResponse,
  DefaultApiWebGetAssetsRequest,
  PinField,
} from "@akitabox/api-client";
import {
  GridFilterModel,
  GridRowSelectionModel,
  GridRowId,
  getGridStringOperators,
  getGridSingleSelectOperators,
  GridSortModel,
  ValueOptions,
  getGridNumericOperators,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";

import { api } from "../api";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import { ReportBlockSelectionDataTemplate } from "./ReportBlockSelectionDataTemplate";
import { AbxDataGrid, AbxGridColDef } from "../lists/abx-data-grid/AbxDataGrid";
import { useInfiniteCall } from "../hooks/useInfiniteCall";
import DataGridPagination from "./DataGridPagination";

/**
 * Simpler version of the built-in filter operators for `singleSelect` columns
 * - Shows `is` operator only
 * @see https://mui.com/x/react-data-grid/filtering/customization/#remove-an-operator
 */
const singleSelectIsOperator = getGridSingleSelectOperators().filter(
  (operator) => operator.value === "is"
);

/**
 * Simpler version of the built-in filter operators for `string` columns
 * - Shows `contains` operator only
 * @see https://mui.com/x/react-data-grid/filtering/customization/#remove-an-operator
 */
const stringContainsOperator = getGridStringOperators().filter(
  (operator) => operator.value === "contains"
);

/**
 * Constant for the limit of pin types to be fetched
 */
const PIN_TYPES_FETCH_LIMIT = 500;

const CONDITION_GRADES_VERBIAGE = [
  "Very Good",
  "Good",
  "Fair",
  "Poor",
  "Very Poor",
  "Failing",
];

export interface ReportBlockSelectionDataGridFilters {
  values?: any;
  name?: string;
  pinType?: string;
  building?: string;
  "condition.condition_rating"?: DefaultApiWebGetAssetsRequest["conditionConditionRating"];
}

export interface ReportBlockBuildingFilters {
  name?: string;
  address?: string;
}

export interface ReportBlockSelectionDataGridDisabledColumns {
  building?: boolean;
}

export interface ReportBlockSelectionDataGridProps {
  organizationId: string;
  selectedReportBlock: ReportBlockResponse | null;
  selectable?: boolean;
  disabledColumns?: ReportBlockSelectionDataGridDisabledColumns;
  setSelectedAssets: Dispatch<SetStateAction<WebAssetResponse[]>>;
  setSelectedBuildings: Dispatch<
    SetStateAction<WebReportBlockBuildingResponse[]>
  >;
  setSelectedReportBlock: (reportBlock: ReportBlockResponse | null) => void;
  // expost the assets to the parent
  onFilterChange?: (filters: ReportBlockSelectionDataGridFilters) => void;
  onAssetCountChange?: (count: number) => void;
  onBuildingsCountChange?: (count: number) => void;
}

export type ReportBlockContext = "asset" | "building";

export const ReportBlockSelectionDataGrid: FunctionComponent<
  ReportBlockSelectionDataGridProps
> = ({
  organizationId,
  selectedReportBlock,
  selectable,
  disabledColumns,
  setSelectedAssets,
  setSelectedBuildings,
  setSelectedReportBlock,
  onAssetCountChange,
  onBuildingsCountChange,
  onFilterChange: onParentFilterChange,
}) => {
  const orgPinTypesFetchStep = useRef(0);
  const assetsMemory = useRef<WebAssetResponse[][]>([]);
  // 2d array because we need to store the list of buildings with their pagination
  const reportBlockBuildingsInMemory = useRef<
    WebReportBlockBuildingResponse[][]
  >([]);
  const [filters, setFilters] = useState<ReportBlockSelectionDataGridFilters>();
  const [buildingFilters, setBuildingFilters] =
    useState<ReportBlockBuildingFilters>();
  const [buildingPinTypes, setBuildingPinTypes] = useState<PinTypeResponse[]>();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [assetSortModel, setAssetSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [buildingSortModel, setBuildingSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);

  const [selectedContext, setSelectedContext] = useState<
    ReportBlockContext | ""
  >("");

  const { data: assetsResponse, isLoading } = useInfiniteCall(
    api.web.getAssets,
    () => [
      {
        ...(assetSortModel.length
          ? { sort: `${assetSortModel[0].field},${assetSortModel[0].sort}` }
          : {}),
        orgId: organizationId,
        // filters
        decommissioned: false,
        name: filters?.name,
        pinType: filters?.pinType,
        values: filters?.values,
        building: filters?.building,
        conditionConditionRating: filters?.["condition.condition_rating"],
        // pagination
        limit: paginationModel.pageSize,
        skip: paginationModel.page * paginationModel.pageSize,
      },
    ]
  );

  const { isLoading: isCounting, data: assetsCountResponse } =
    makeUseServiceCall(api.web.getAssetsCount)({
      orgId: organizationId,
      // filters
      decommissioned: false,
      name: filters?.name,
      pinType: filters?.pinType,
      values: filters?.values,
      building: filters?.building,
    });

  const { data: buildingsResponse } = makeUseServiceCall(
    api.buildings.getByOrganization
  )({
    skip: 0,
    limit: 1000,
    organizationId,
  });

  const { data: orgPinTypesResponse, mutate: mutateOrgPinTypesResponse } =
    makeUseServiceCall(api.pinTypes.getByOrganization)({
      skip: 0,
      organizationId,
      limit: PIN_TYPES_FETCH_LIMIT,
    });

  const { data: orgPinTypesCountResponse } = makeUseServiceCall(
    api.pinTypes.count
  )({ organizationId });

  const orgPinTypesCount = useMemo(() => {
    if (!orgPinTypesCountResponse) return 0;
    return orgPinTypesCountResponse.data.count;
  }, [orgPinTypesCountResponse]);

  const assetsCount = useMemo(() => {
    if (!assetsCountResponse) return 0;
    return assetsCountResponse.data.count;
  }, [assetsCountResponse]);

  const assets = useMemo(() => {
    if (!assetsResponse) return [];
    const flattenedItems = assetsResponse.flatMap((item) => item.data);
    return flattenedItems;
  }, [assetsResponse]);

  const buildings = useMemo(
    () => (buildingsResponse ? buildingsResponse.data : []),
    [buildingsResponse]
  );

  const orgPinTypes = useMemo(() => {
    if (!orgPinTypesResponse) return [];

    return orgPinTypesResponse.data.reduce<PinTypeResponse[]>(
      (previous, current) => {
        const hasPinType = previous.some(
          (pinType) => pinType.name === current.name
        );

        if (!hasPinType) previous.push(current);

        return previous;
      },
      []
    );
  }, [orgPinTypesResponse]);

  // We need to pull for buildings that will show up in the table when a user seleccts the building
  // context
  const {
    data: reportBlockBuildingsResponse,
    isLoading: isReportBlockBuildingLoading,
  } = makeUseServiceCall(api.web.getReportBlockBuildings)({
    ...(buildingSortModel.length
      ? { sort: `${buildingSortModel[0].field},${buildingSortModel[0].sort}` }
      : {}),
    organizationId,
    name: buildingFilters?.name,
    address: buildingFilters?.address,
    // pagination
    limit: paginationModel.pageSize,
    skip: paginationModel.page * paginationModel.pageSize,
  });

  const reportBlockBuildings = useMemo(
    () =>
      reportBlockBuildingsResponse ? reportBlockBuildingsResponse.data : [],
    [reportBlockBuildingsResponse]
  );

  const {
    isLoading: isCountingReportBlockBuildings,
    data: reportBlockBuildingsCountResponse,
  } = makeUseServiceCall(api.web.getReportBlockBuildingCount)({
    organizationId,
    name: buildingFilters?.name,
    address: buildingFilters?.address,
  });

  const reportBlockBuildingCount = useMemo(() => {
    if (!reportBlockBuildingsCountResponse) return 0;
    return reportBlockBuildingsCountResponse.data.count;
  }, [reportBlockBuildingsCountResponse]);

  const buildingColumns = useMemo<
    AbxGridColDef<WebReportBlockBuildingResponse>[]
  >(
    () => [
      {
        field: "name",
        type: "string",
        headerName: "Name",
        abxGridColType: "basic",
        filterOperators: stringContainsOperator,
      },
      {
        field: "address",
        type: "string",
        headerName: "Address",
        abxGridColType: "basic",
        filterOperators: stringContainsOperator,
      },
    ],
    []
  );

  const stringFilterOperators = getGridStringOperators().filter(
    (o) => o.value === "contains" || o.value === "equals"
  );

  const numericFilterOperators = getGridNumericOperators().filter(
    (o) =>
      o.value !== "isEmpty" && o.value !== "isNotEmpty" && o.value !== "isAnyOf"
  );

  const getAllEnumValues = useCallback(
    (pinField: PinField) => {
      if (!orgPinTypesResponse || !orgPinTypesResponse.data) return [];

      return [
        ...new Set(
          orgPinTypesResponse.data.flatMap((pinType) =>
            pinType.fields
              .filter((field) => field.name === pinField.name)
              .flatMap((field) => field.acceptable_enum_values)
          )
        ),
      ].sort();
    },
    [orgPinTypesResponse]
  );

  const columns = useMemo<AbxGridColDef<WebAssetResponse>[]>(() => {
    let initialColumns: AbxGridColDef<WebAssetResponse>[] = [
      {
        field: "name",
        type: "string",
        headerName: "Name",
        abxGridColType: "basic",
        filterOperators: stringContainsOperator,
      },
      {
        field: "building",
        type: "singleSelect",
        headerName: "Building",
        valueOptions: buildings,
        abxGridColType: "basic",
        filterOperators: singleSelectIsOperator,
        renderCell: ({ row: asset }) => asset.building.name,
        getOptionValue: (value?: any) => value?._id ?? "",
        getOptionLabel: (value?: any) => value?.name,
      },
      {
        field: "pinType",
        type: "singleSelect",
        headerName: "Category",
        abxGridColType: "basic",
        filterOperators: singleSelectIsOperator,
        valueOptions: buildingPinTypes || orgPinTypes,
        renderCell: ({ row: asset }) => asset.pinType.name,
        getOptionValue: (value?: any) => value?.name ?? "",
        getOptionLabel: (value?: any) => value?.name,
      },
      {
        field: "condition",
        type: "singleSelect",
        headerName: "Condition",
        abxGridColType: "basic",
        filterOperators: singleSelectIsOperator,
        valueOptions: CONDITION_GRADES_VERBIAGE,
        renderCell: ({ row: asset }) => {
          return asset.condition?.condition_rating || "";
        },
      },
    ];

    if (disabledColumns && disabledColumns.building) {
      // remove building column because it is disabled
      initialColumns = initialColumns.filter(
        (column) => column.field !== "building"
      );
    }

    const addedColumns: { [key: string]: boolean } = {};

    for (const orgPinType of orgPinTypes) {
      for (const field of orgPinType.fields) {
        let valueOptions: ValueOptions = [];

        if (
          field.is_hidden ||
          field.is_level_field ||
          field.is_room_field ||
          field.data_type === "document_array" ||
          addedColumns[`${field.name}_${field.data_type}`]
        ) {
          continue;
        }

        let type = "string";
        if (field.data_type === "float" || field.data_type === "int") {
          type = "number";
        } else if (field.data_type === "enum") {
          type = "singleSelect";
          valueOptions = getAllEnumValues(field);
        }

        let filterOperators;
        if (field.data_type === "float" || field.data_type === "int") {
          filterOperators = numericFilterOperators;
        } else if (field.data_type === "enum") {
          filterOperators = singleSelectIsOperator;
        } else {
          filterOperators = stringFilterOperators;
        }
        addedColumns[`${field.name}_${field.data_type}`] = true;
        initialColumns.push({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - this is a valid field
          field: `values.${field.name}`,
          type: type as "string" | "number" | "singleSelect",
          headerName: `${field.name} (${field.data_type})`,
          abxGridColType: "basic",
          filterOperators: filterOperators,
          renderCell: ({ row: asset }) => {
            const value =
              (asset.values[field.name as keyof typeof asset.values] as {
                value: any;
              }) || undefined;
            if (field.data_type === "date") {
              return value && value.value
                ? new Date(value.value).toLocaleDateString()
                : "";
            }

            return value ? value.value : "";
          },
          ...(type === "singleSelect" ? { valueOptions } : {}),
          ...(type === "singleSelect"
            ? { getOptionValue: (option: string) => option }
            : {}),
          ...(type === "singleSelect"
            ? { getOptionLabel: (option: string) => option }
            : {}),
          minWidth: 150,
        });
      }
    }

    return initialColumns;
  }, [
    buildings,
    buildingPinTypes,
    orgPinTypes,
    disabledColumns,
    getAllEnumValues,
    numericFilterOperators,
    stringFilterOperators,
  ]);

  const getPinTypesIds = useCallback(
    (pinTypeName: string, pinTypes: PinTypeResponse[]) =>
      pinTypes
        .filter((pinType) => pinType.name === pinTypeName)
        .map((pinType) => pinType._id)
        .join(),
    []
  );

  const onBuildingFilterChange = useCallback(
    async ({ items }: GridFilterModel) => {
      const filters: ReportBlockBuildingFilters = {};

      const nameFilter = items.find(({ field }) => field === "name");
      if (nameFilter && nameFilter.value) {
        filters.name = nameFilter.value;
      }

      const addressFilter = items.find(({ field }) => field === "address");
      if (addressFilter && addressFilter.value) {
        filters.address = addressFilter.value;
      }

      setBuildingFilters(filters);

      if (onParentFilterChange) {
        onParentFilterChange(filters);
      }
    },
    [onParentFilterChange]
  );

  const onAssetFilterChange = useCallback(
    async ({ items }: GridFilterModel) => {
      const filters: ReportBlockSelectionDataGridFilters = {};

      const nameFilter = items.find(({ field }) => field === "name");
      if (nameFilter && nameFilter.value) {
        filters.name = nameFilter.value;
      }

      let buildingPinTypes: PinTypeResponse[] | undefined;
      const buildingFilter = items.find(({ field }) => field === "building");
      if (buildingFilter && buildingFilter.value) {
        filters.building = buildingFilter.value;

        try {
          const { data } = await api.pinTypes.getByOrganization({
            organizationId,
            building: filters.building,
          });
          buildingPinTypes = data;
        } catch (e) {
          throw new Error(e instanceof Error ? e.message : String(e));
        }
      }

      const conditionFilter = items.find(({ field }) => field === "condition");
      if (conditionFilter && conditionFilter.value) {
        filters["condition.condition_rating"] = conditionFilter.value;
      }

      setBuildingPinTypes(buildingPinTypes);

      const pinTypeFilter = items.find(({ field }) => field === "pinType");
      if (pinTypeFilter && pinTypeFilter.value) {
        let ids = "";

        if (buildingPinTypes) {
          ids = getPinTypesIds(pinTypeFilter.value, buildingPinTypes);
        } else if (orgPinTypesResponse) {
          ids = getPinTypesIds(pinTypeFilter.value, orgPinTypesResponse.data);
        }

        filters.pinType = `$in,${ids}`;
      }

      const customFields = items.filter(({ field }) =>
        field.includes("values.")
      );

      const customFieldFilters: { [key: string]: any } = {};

      customFields.reduce((acc, { field, value, operator }) => {
        const fieldName = field.split("values.")[1];
        const filterKey = fieldName;
        let filterValue;
        switch (operator) {
          case "is":
          case "equals":
            filterValue = `{"$eq": "${value}"}`;
            break;
          case "notEquals":
            filterValue = `{"$ne": "${value}"}`;
            break;
          case "=":
            filterValue = `{"$eq": ${value}}`;
            break;
          case "!=":
            filterValue = `{"$ne": ${value}}`;
            break;
          case ">":
            filterValue = `{"$gt": ${value}}`;
            break;
          case ">=":
            filterValue = `{"$gte": ${value}}`;
            break;
          case "<":
            filterValue = `{"$lt": ${value}}`;
            break;
          case "<=":
            filterValue = `{"$lte": ${value}}`;
            break;
          case "contains":
            filterValue = `{"$regex":"${value}","$options":"i"}`;
            break;
          default:
            filterValue = `{"eq": ${value}}`;
            break;
        }
        customFieldFilters[filterKey] = filterValue;
        return acc;
      }, {});

      if (Object.keys(customFieldFilters).length) {
        filters.values = customFieldFilters;
      }

      setFilters(filters);
      if (onParentFilterChange) onParentFilterChange(filters);
    },
    [onParentFilterChange, organizationId, orgPinTypesResponse, getPinTypesIds]
  );

  const findAssetInMemory = useCallback(
    (assetId: GridRowId) => {
      const flatted = assetsMemory.current.flat();
      return flatted.find((asset) => asset._id === assetId);
    },
    [assetsMemory]
  );

  const onAssetRowSelectionModelChange = useCallback(
    (assetsIds: GridRowSelectionModel) => {
      const selectedAssets = assetsIds.reduce<WebAssetResponse[]>(
        (accumulator, current) => {
          const asset = findAssetInMemory(current);
          if (asset) {
            accumulator.push(asset);
          }

          return accumulator;
        },
        []
      );

      setSelectedAssets(selectedAssets);
    },
    [findAssetInMemory, setSelectedAssets]
  );

  const findReportBlockBuildingInMemory = useCallback(
    (buildingId: GridRowId) => {
      const flatted = reportBlockBuildingsInMemory.current.flat();
      return flatted.find((building) => building._id === buildingId);
    },
    [reportBlockBuildingsInMemory]
  );

  const onBuildingRowSelectionModelChange = useCallback(
    (buildingIds: GridRowSelectionModel) => {
      const selectedBuildings = buildingIds.reduce<
        WebReportBlockBuildingResponse[]
      >((accumulator, current) => {
        const building = findReportBlockBuildingInMemory(current);
        if (building) {
          accumulator.push(building);
        }

        return accumulator;
      }, []);

      setSelectedBuildings(selectedBuildings);
    },
    [findReportBlockBuildingInMemory, setSelectedBuildings]
  );

  const handleAssetListSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setAssetSortModel(sortModel);
    },
    []
  );

  const handleBuildingListSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setBuildingSortModel(sortModel);
    },
    []
  );

  useEffect(() => {
    if (!assets) return;
    const { page } = paginationModel;
    // matrix that stores the list of assets
    // indexed by the current pagination page
    assetsMemory.current[page] = assets;
  }, [assets, paginationModel]);

  useEffect(() => {
    if (!reportBlockBuildingsResponse) return;
    const { page } = paginationModel;
    // matrix that stores the list of assets
    // indexed by the current pagination page
    reportBlockBuildingsInMemory.current[page] =
      reportBlockBuildingsResponse.data;
  }, [reportBlockBuildingsResponse, paginationModel]);

  // Update the parent's count of the current amount of assets for the "Insert All" button
  useEffect(() => {
    onAssetCountChange?.(assetsCount);
  }, [assetsCount, onAssetCountChange]);

  // Update the parent's count of the current amount of buildings for the "Insert All" button
  useEffect(() => {
    onBuildingsCountChange?.(reportBlockBuildingCount);
  }, [reportBlockBuildingCount, onBuildingsCountChange]);

  useEffect(() => {
    if (
      !orgPinTypesResponse ||
      orgPinTypesCount === 0 ||
      orgPinTypesResponse.data.length === orgPinTypesCount
    ) {
      return;
    }

    (async () => {
      try {
        orgPinTypesFetchStep.current += PIN_TYPES_FETCH_LIMIT;
        const { data } = await api.pinTypes.getByOrganization({
          organizationId,
          limit: PIN_TYPES_FETCH_LIMIT,
          skip: orgPinTypesFetchStep.current,
        });

        mutateOrgPinTypesResponse(
          {
            ...orgPinTypesResponse,
            data: [...orgPinTypesResponse.data, ...data],
          },
          { revalidate: false }
        );
      } catch (e) {
        throw new Error(e instanceof Error ? e.message : String(e));
      }
    })();
  }, [
    organizationId,
    orgPinTypesCount,
    orgPinTypesResponse,
    mutateOrgPinTypesResponse,
  ]);

  const initialColumnVisibilityModel = useMemo(() => {
    const columnVisibilityModel: Record<string, boolean> = {};
    columns.forEach((column) => {
      if (
        column.field === "name" ||
        column.field === "building" ||
        column.field === "pinType"
      ) {
        columnVisibilityModel[column.field] = true;
      } else {
        columnVisibilityModel[column.field] = false;
      }
    });
    return columnVisibilityModel;
  }, [columns]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  };

  return (
    <Stack flexWrap="nowrap">
      <ReportBlockSelectionDataTemplate
        organizationId={organizationId}
        onReportBlockChange={setSelectedReportBlock}
        onContextChange={setSelectedContext}
      />
      {selectedReportBlock && selectedContext === "asset" && (
        <AbxDataGrid
          css={(theme) => ({ marginTop: theme.spacing(2) })}
          autoHeight
          pagination
          rows={assets}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: initialColumnVisibilityModel,
            },
          }}
          slots={{
            toolbar: CustomToolbar,
            pagination: () => <DataGridPagination />,
          }}
          disableDensitySelector
          checkboxSelection={selectable === undefined ? true : selectable}
          filterMode="server"
          disableColumnPinning
          rowCount={assetsCount}
          paginationMode="server"
          sortingMode="server"
          onSortModelChange={handleAssetListSortModelChange}
          disableMultipleColumnsSorting
          keepNonExistentRowsSelected
          loading={isLoading || isCounting}
          paginationModel={paginationModel}
          getDetailPanelHeight={() => "auto"}
          onFilterModelChange={onAssetFilterChange}
          pageSizeOptions={[paginationModel.pageSize]}
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={onAssetRowSelectionModelChange}
          /**
           * New/unstable MUI X Pro feature
           * @see https://mui.com/x/react-data-grid/filtering/header-filters/
           */
          headerFilters
        />
      )}
      {selectedReportBlock && selectedContext === "building" && (
        <AbxDataGrid
          css={(theme) => ({ marginTop: theme.spacing(2) })}
          autoHeight
          pagination
          rows={reportBlockBuildings}
          columns={buildingColumns}
          checkboxSelection={selectable === undefined ? true : selectable}
          disableColumnFilter
          filterMode="server"
          disableColumnPinning
          rowCount={reportBlockBuildingCount}
          paginationMode="server"
          keepNonExistentRowsSelected
          sortingMode="server"
          onSortModelChange={handleBuildingListSortModelChange}
          disableMultipleColumnsSorting
          loading={
            isReportBlockBuildingLoading || isCountingReportBlockBuildings
          }
          paginationModel={paginationModel}
          slots={{ headerFilterMenu: null }}
          getDetailPanelHeight={() => "auto"}
          onFilterModelChange={onBuildingFilterChange}
          pageSizeOptions={[paginationModel.pageSize]}
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={onBuildingRowSelectionModelChange}
          /**
           * New/unstable MUI X Pro feature
           * @see https://mui.com/x/react-data-grid/filtering/header-filters/
           */
          headerFilters
        />
      )}
    </Stack>
  );
};
