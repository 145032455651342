import { useCallback, useState, useEffect, useMemo } from "react";
import {
  Avatar,
  Button,
  Popover,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  Typography,
} from "@mui/material";
import { PhotoLibrary } from "@mui/icons-material";
import { api } from "../api";
import { AttachmentResponse, WebAssetResponse } from "@akitabox/api-client";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import { AttachmentDialog } from "../attachment-dialog/AttachmentDialog";
import { useAngularApp } from "../legacy/angularApp";

type LinkPreviewProps = {
  asset: any;
  anchorEl: any;
  onClose: () => void;
};

const AttachmentsPreviewButton = ({
  asset,
  onClick,
}: {
  asset: any;
  onClick: () => void;
}) => {
  return (
    <IconButton
      style={{ color: asset?.has_attachments ? "#2d8dd9" : "#999999" }}
      onClick={onClick}
      aria-haspopup="true"
    >
      <PhotoLibrary />
    </IconButton>
  );
};

type AttachmentPreviewCardProps = {
  asset: WebAssetResponse;
  showHeader: boolean;
  applyStyles?: boolean;
};
const AttachmentPreviewCard = ({
  asset,
  showHeader = true,
  applyStyles = true,
}: AttachmentPreviewCardProps) => {
  const [documents, setDocuments] = useState<any[]>([]);
  // const [hoveredItemId, setHoveredItemId] = useState(null);
  const { data: attachmentResponse, isLoading: isLoading } = makeUseServiceCall(
    api.attachments.getByBuilding
  )({
    building: asset?.building?._id,
    linksEntityType: "asset",
    linksEntityId: `$in,${asset?._id}`,
  });

  const attachments = useMemo(() => {
    return attachmentResponse?.data || [];
  }, [attachmentResponse]);

  useEffect(() => {
    setDocuments(attachments);
  }, [attachments]);

  const [isAttachmentDialogOpen, setIsAttachmentDialogOpen] = useState(false); // State to manage AttachmentDialog visibility
  const handleOpenAttachmentDialog = () => {
    setIsAttachmentDialogOpen(true);
  };

  const injector = useAngularApp();

  const handleLegacyFilePreview = useCallback(
    async (asset: WebAssetResponse, doc: AttachmentResponse) => {
      const { building, ...previewAsset } = asset;
      const transformedAsset = {
        ...previewAsset,
        building: building?._id,
      };

      await injector.get<any>("FilePreviewDialog").show({
        locals: {
          pin: transformedAsset,
          documentId: doc._id,
          supportMultipleFiles: true,
          // documentOrder,
          documentOrder: [],
        },
      });
    },
    [injector]
  );

  return (
    <>
      <Card
        variant="outlined"
        sx={
          applyStyles
            ? {
                width: "100%",
                minWidth: 350,
                boxShadow: "none",
                borderRadius: 1,
                transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                "&:hover": {
                  boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
                  transform: "scale(1.02)",
                },
              }
            : {}
        }
      >
        {showHeader ? (
          <CardHeader
            avatar={
              <Avatar
                sx={{ bgcolor: `${asset?.color}` }}
                alt={asset?.display_name}
                src={`/img/pin_icons/${asset?.icon}.svg`}
              />
            }
            title={`${asset?.display_name} Attachments`}
            subheader={`${documents.length} images`}
          />
        ) : (
          <CardHeader title={`Attachments`} />
        )}
        <CardContent style={{ maxHeight: "400px", overflowY: "auto" }}>
          {isLoading ? (
            <CircularProgress style={{ display: "block", margin: "auto" }} />
          ) : documents.length === 0 ? (
            <Typography
              variant="body2"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              No documents available.
            </Typography>
          ) : (
            <ImageList cols={2}>
              {documents.map((doc: AttachmentResponse) => (
                <ImageListItem
                  key={doc._id}
                  style={{
                    width: "130px",
                    height: "130px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => handleLegacyFilePreview(asset, doc)}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={doc?.document?.public_thumbnail_url_medium}
                      alt={doc?.document?.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      loading="lazy"
                    />
                  </div>
                  <ImageListItemBar
                    title={
                      <Tooltip title={doc?.document?.name}>
                        <Typography
                          variant="body2"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {doc?.document?.name}
                        </Typography>
                      </Tooltip>
                    }
                    subtitle={
                      <Tooltip
                        title={
                          doc?.document?.last_mod_date
                            ? new Date(doc.last_mod_date).toLocaleString(
                                "en-US",
                                {
                                  month: "short",
                                  day: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                }
                              )
                            : "No date available"
                        }
                      >
                        <Typography
                          variant="body2"
                          style={{ fontSize: "0.7rem" }}
                        >
                          {doc?.document?.last_mod_date
                            ? new Date(doc.last_mod_date).toLocaleString(
                                "en-US",
                                {
                                  month: "short",
                                  day: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                }
                              )
                            : "No date available"}
                        </Typography>
                      </Tooltip>
                    }
                    position="below"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </CardContent>
        <CardActions>
          <Button onClick={handleOpenAttachmentDialog} size="small">
            Add Attachments
          </Button>
        </CardActions>
      </Card>
      <AttachmentDialog
        organization={asset.organization}
        building={asset?.building?._id}
        onClose={() => setIsAttachmentDialogOpen(false)}
        entityType={"asset"}
        entityIds={[asset?._id]}
        skipCreatingAttachments={false}
        open={isAttachmentDialogOpen}
      />
    </>
  );
};

// const AttachmentPreviewCard = ({
//   asset,
//   showHeader,
// }: AttachmentPreviewContentProps) => {
//   return (
//     <Card
//       variant="outlined"
//       sx={{
//         maxHeight: 500,
//         width: "100%",
//         minWidth: 350,
//         boxShadow: "none",
//         borderRadius: 1,
//         transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
//         "&:hover": {
//           boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
//           transform: "scale(1.02)",
//         },
//       }}
//     >
//       <AttachmentPreviewContent asset={asset} showHeader={showHeader} />
//     </Card>
//   );
// };

const AttachmentsPreview = ({ asset, anchorEl, onClose }: LinkPreviewProps) => {
  const [isAttachmentDialogOpen, setIsAttachmentDialogOpen] = useState(false); // State to manage AttachmentDialog visibility

  return (
    <>
      <Popover
        id="mouse-over-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <AttachmentPreviewCard
          asset={asset}
          showHeader={true}
          applyStyles={false}
        />
      </Popover>
      <AttachmentDialog
        organization={asset?.organization?._id}
        building={asset?.building?._id}
        onClose={() => setIsAttachmentDialogOpen(false)}
        entityType={"asset"}
        entityIds={[asset?._id]}
        skipCreatingAttachments={false}
        open={isAttachmentDialogOpen}
      />
    </>
  );
};

export { AttachmentsPreviewButton, AttachmentsPreview, AttachmentPreviewCard };
